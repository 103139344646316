import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Views from './views';
import { ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: '#0B3E83',
        },
        secondary: {
            main: '#F25C05',
        },
        publicidad: {
            main: '#F20505',
            contrastText: '#E0F3FD',
        },
        fundraising: {
            main: '#0B3E83',
            contrastText: '#E0F3FD',
        },
        automotriz: {
            main: '#059DF2',
            contrastText: '#E0F3FD',
        },
        entretenimiento: {
            main: '#05F247',
            contrastText: '#E0F3FD',
        },
        hyt: {
            main: '#05F247',
            contrastText: '#E0F3FD',
        },
        bienestar: {
            main: '#05F247',
            contrastText: '#E0F3FD',
        },
        f2f: {
            main: '#A605F2',
            contrastText: '#E0F3FD',
        },
        tmk: {
            main: '#05F247',
            contrastText: '#E0F3FD',
        },
        text: {
            main: '#4A4A4A',
        },
    },
});

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={defaultTheme}>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/*"
                                    element={<Views></Views>}></Route>
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
