import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    borderBottom: 'solid #0B3E83 1px',
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 2,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

export default function NuevoCliente({
    handleClose,
    handleCrear,
    tipoDocumentos,
    generos,
    estadosCiviles,
    edadesHijosList,
    nivelDeEstudiosList,
    departamentosList,
    departamento,
    setDepartamento,
    ciudadesList,
}) {
    const [nombres, setNombres] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState(dayjs(new Date()));
    const [edad, setEdad] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [genero, setGenero] = useState(null);
    const [telFijo, setTelFijo] = useState('');
    const [celular, setCelular] = useState('');
    const [correo, setCorreo] = useState('');
    const [tieneTc, setTieneTc] = useState(false);
    const [donanteRecurrente, setDonanteRecurrente] = useState(false);
    const [ciudad, setCiudad] = useState('');
    const [direccionExacta, setDireccionExacta] = useState('');
    const [estadoCivil, setEstadoCivil] = useState(null);
    const [hijos, setHijos] = useState(false);
    const [edadesHijos, setEdadesHijos] = useState(null);
    const [nivelDeEstudios, setNivelDeEstudios] = useState(null);
    const [ocupacion, setOcupacion] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [direccionEmpresa, setDireccionEmpresa] = useState('');
    const [tratamientoDatos, setTratamientoDatos] = useState(false);

    const handleChangeTipoDocumento = (event) => {
        setTipoDocumento(event.target.value);
    };

    const handleChangeTipoTc = (event) => {
        setTieneTc(event.target.value);
    };

    const handleChangeDonanteRecurrente = (event) => {
        setDonanteRecurrente(event.target.value);
    };

    const handleHijos = (event) => {
        setHijos(event.target.value);
    };

    const handleTratamientoDatos = (event) => {
        setTratamientoDatos(event.target.value);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary">
                        Crear Nuevo Cliente
                    </Typography>
                </Box>

                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Información Personal
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="nombres"
                            label="Nombres"
                            placeholder="Nombres del cliente"
                            variant="outlined"
                            value={nombres}
                            onChange={(event) => setNombres(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="apellidos"
                            label="Apellidos"
                            placeholder="Apellidos del cliente"
                            variant="outlined"
                            value={apellidos}
                            onChange={(event) =>
                                setApellidos(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                id="fecha_nacimiento"
                                label="Fecha Nacimiento"
                                value={fechaNacimiento}
                                onChange={(event) =>
                                    setFechaNacimiento(
                                        dayjs(event).toISOString(),
                                    )
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="edad"
                            label="Edad"
                            placeholder="Edad"
                            variant="outlined"
                            value={edad}
                            onChange={(event) => setEdad(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="tipo-documento">
                                Tipo Documento
                            </InputLabel>
                            <Select
                                labelId="tipo-documento"
                                label="Tipo Documento"
                                value={tipoDocumento}
                                onChange={handleChangeTipoDocumento}>
                                <MenuItem disabled value="">
                                    Seleccione un tipo de documento
                                </MenuItem>
                                {tipoDocumentos.map((tipoDocumento) => {
                                    return (
                                        <MenuItem
                                            key={tipoDocumento.id}
                                            value={tipoDocumento.id}>
                                            {tipoDocumento.tipo}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="numero_documento"
                            label="Número de Documento"
                            placeholder="1234567890"
                            variant="outlined"
                            value={numeroDocumento}
                            onChange={(event) =>
                                setNumeroDocumento(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            select
                            id="genero"
                            label="Género"
                            placeholder="M, F, Otro"
                            variant="outlined"
                            value={genero}
                            onChange={(event) => setGenero(event.target.value)}>
                            {generos &&
                                generos.map((genero) => {
                                    return (
                                        <MenuItem
                                            key={genero.id}
                                            value={genero.id}>
                                            {genero.genero}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="tel_fijo"
                            label="Teléfono Fijo"
                            placeholder="1234567"
                            variant="outlined"
                            value={telFijo}
                            onChange={(event) => setTelFijo(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="celular"
                            label="Celular"
                            placeholder="3001234567"
                            variant="outlined"
                            value={celular}
                            onChange={(event) => setCelular(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="correo"
                            label="Correo"
                            placeholder="correo@correo.com"
                            variant="outlined"
                            value={correo}
                            onChange={(event) => setCorreo(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="tienetc">
                                Tiene Tarjeta de Crédito
                            </InputLabel>
                            <Select
                                labelId="tienetc"
                                label="Tiene Tarjeta de Crédito"
                                value={tieneTc}
                                onChange={handleChangeTipoTc}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="donante-recurrente">
                                Donante Recurrente
                            </InputLabel>
                            <Select
                                labelId="donante-recurrente"
                                label="Donante Recurrente"
                                value={donanteRecurrente}
                                onChange={handleChangeDonanteRecurrente}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Ubicación
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="departamento"
                            label="Departamento"
                            value={departamento}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setDepartamento(event.target.value)
                            }>
                            {departamentosList &&
                                departamentosList.map((departamento) => {
                                    return (
                                        <MenuItem
                                            key={departamento.id}
                                            value={departamento.id}>
                                            {departamento.nombre}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    {departamento && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                id="ciudad"
                                label="Ciudad"
                                value={ciudad}
                                variant="outlined"
                                width="100%"
                                onChange={(event) =>
                                    setCiudad(event.target.value)
                                }>
                                {ciudadesList &&
                                    ciudadesList.map((ciudad) => {
                                        return (
                                            <MenuItem
                                                key={ciudad.id}
                                                value={ciudad.id}>
                                                {ciudad.nombre}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="direccion_exacta"
                            label="Dirección Exacta"
                            placeholder="Cra 1 # 2 - 3"
                            variant="outlined"
                            value={direccionExacta}
                            onChange={(event) =>
                                setDireccionExacta(event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Hogar
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            select
                            id="estado_civil"
                            label="Estado Civil"
                            value={estadoCivil}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setEstadoCivil(event.target.value)
                            }>
                            {estadosCiviles &&
                                estadosCiviles.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.estado}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="hijos">Hijos</InputLabel>
                            <Select
                                labelId="hijos"
                                label="hijos"
                                value={hijos}
                                onChange={handleHijos}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {hijos && (
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    select
                                    id="edades_hijos"
                                    label="Edades Hijos"
                                    value={edadesHijos}
                                    variant="outlined"
                                    width="100%"
                                    onChange={(event) =>
                                        setEdadesHijos(event.target.value)
                                    }>
                                    {edadesHijosList &&
                                        edadesHijosList.map((item) => {
                                            return (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.rango}
                                                </MenuItem>
                                            );
                                        })}
                                </TextField>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Formación Académica y Profesional
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            select
                            id="nivel_de_estudios"
                            label="Nivel de Estudios"
                            value={nivelDeEstudios}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setNivelDeEstudios(event.target.value)
                            }>
                            {nivelDeEstudiosList &&
                                nivelDeEstudiosList.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nivel}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="ocupacion"
                            label="Ocupación"
                            placeholder="Profesor, Gerente..."
                            variant="outlined"
                            value={ocupacion}
                            onChange={(event) =>
                                setOcupacion(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="empresa"
                            label="Empresa"
                            placeholder="Nombre de la empresa"
                            variant="outlined"
                            value={empresa}
                            onChange={(event) => setEmpresa(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="direccion_empresa"
                            label="Dirección Empresa"
                            placeholder="Dirección de la empresa"
                            variant="outlined"
                            value={direccionEmpresa}
                            onChange={(event) =>
                                setDireccionEmpresa(event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Otros
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="tratamiento-datos">
                                Tratamiento de Datos
                            </InputLabel>
                            <Select
                                labelId="tratamiento-datos"
                                label="Tratamiento Datos"
                                value={tratamientoDatos}
                                onChange={handleTratamientoDatos}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                handleCrear({
                                    nombres,
                                    apellidos,
                                    fecha_nacimiento: fechaNacimiento,
                                    edad,
                                    tipo_documento: tipoDocumento,
                                    numero_documento: numeroDocumento,
                                    genero,
                                    tel_fijo: telFijo,
                                    celular,
                                    correo,
                                    tiente_tc: tieneTc,
                                    donante_recurrente: donanteRecurrente,
                                    ciudad,
                                    departamento,
                                    direccion_exacta: direccionExacta,
                                    estado_civil: estadoCivil,
                                    hijos,
                                    edades_hijos: edadesHijos,
                                    nivel_de_estudios: nivelDeEstudios,
                                    ocupacion,
                                    empresa,
                                    direccion_empresa: direccionEmpresa,
                                    tratamiento_datos: tratamientoDatos,
                                })
                            }>
                            Crear
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
