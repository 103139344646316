import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    borderBottom: 'solid #0B3E83 1px',
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 2,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const cantidades = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

const Preventa = ({ cliente, mediosPago, handleClose, handleAgendar }) => {
    const [medioPagoSelected, setMedioPagoSelected] = useState(null);
    const [cantidadSelected, setCantidadSelected] = useState(null);
    const [observaciones, setObservaciones] = useState(null);
    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary">
                        Detalle Preventa
                    </Typography>
                </Box>
                <Grid container spacing={5} sx={{ my: '5px' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            id="nombre-cliente"
                            label="Nombre Cliente"
                            variant="outlined"
                            width="100%"
                            value={`${cliente?.cliente?.nombres} ${
                                cliente.cliente.apellidos
                                    ? cliente.cliente.apellidos
                                    : ''
                            }`}></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            id="telefono-cliente"
                            label="Telefono Cliente"
                            variant="outlined"
                            width="100%"
                            value={`${
                                cliente.cliente.celular
                                    ? cliente.cliente.celular
                                    : ''
                            }`}></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            id="direccion-cliente"
                            label="Dirección Cliente"
                            variant="outlined"
                            width="100%"
                            value={`${
                                cliente.cliente.direccion_exacta
                                    ? cliente.cliente.direccion_exacta
                                    : ''
                            }`}></TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="medio-pago"
                            label="Medio de Pago"
                            variant="outlined"
                            width="100%"
                            value={medioPagoSelected}
                            onChange={(event) =>
                                setMedioPagoSelected(event.target.value)
                            }>
                            {mediosPago.length > 0 &&
                                mediosPago.map((medioPago) => (
                                    <MenuItem
                                        key={medioPago.id}
                                        value={medioPago.id}>
                                        {medioPago.nombre}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            select
                            type="number"
                            id="cantidad"
                            label="Cantidad Comprada"
                            variant="outlined"
                            width="100%"
                            value={cantidadSelected}
                            onChange={(e) =>
                                setCantidadSelected(e.target.value)
                            }>
                            {cantidades.length > 0 &&
                                cantidades.map((cantidad) => (
                                    <MenuItem
                                        key={cantidad.id}
                                        value={cantidad.id}>
                                        {cantidad.id}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            id="observacion"
                            label="Observaciones"
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setObservaciones(event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}>
                            Cancelar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                handleAgendar({
                                    cliente: cliente.cliente.id,
                                    campana: cliente.campana.id,
                                    equipo: cliente.equipo.id,
                                    envio: {
                                        medio_pago: medioPagoSelected,
                                        cantidad: cantidadSelected,
                                        observaciones: observaciones,
                                    },
                                    contactabilidad: 11,
                                })
                            }>
                            Agendar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default Preventa;
