import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useState } from 'react';

const ResumenInventario = ({ pais }) => {
    const [rows /* setRows */] = useState();
    const [limit, setLimit] = useState(50);
    const [, /* page */ setPage] = useState(0);
    const [rowCount /* setRowCount */] = useState(null);

    //Condicionales
    const [loading /* setLoading */] = useState(false);

    const theme = useTheme();
    /* const URL = process.env.REACT_APP_API_URL; */

    /* useEffect(() => {
        sendRequest('get', `${URL}/${pais}/inventario`).then((res) => {
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, []); */

    const columns = [
        {
            field: 'id',
            headerName: 'Número',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
        },
        {
            field: 'campana',
            headerName: 'Campaña',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.equipo?.campana?.nombre,
        },
        {
            field: 'equipo',
            headerName: 'Equipo',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.equipo?.nombre,
        },
        {
            field: 'ciudad',
            headerName: 'Ciudad',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.ciudad?.nombre,
        },
        {
            field: 'saldo',
            headerName: 'Saldo',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.equipo?.campana?.tarjetones?.length,
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Typography
                    variant="h4"
                    color="primary"
                    sx={{
                        textAlign: 'start',
                        marginLeft: '10px',
                        marginTop: '20px',
                    }}>
                    Resumen
                </Typography>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default ResumenInventario;
