import { compose, configureStore } from '@reduxjs/toolkit';
import reducers from '../reducers';
import {
    createStateSyncMiddleware,
    initMessageListener,
} from 'redux-state-sync';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loadState, saveState } from '../../utils/localstorage';

const persistedState = loadState();

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducers = persistReducer(persistConfig, reducers);

const composeEnhancers =
    (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const store = configureStore({
    reducer: persistedReducers,
    preloadedState: persistedState,
    composeEnhancers,
    middleware: () => [
        createStateSyncMiddleware({
            blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
        }),
    ],
});

initMessageListener(store);

store.subscribe(() => {
    saveState({
        state: store.getState(),
    });
});

export const persistor = persistStore(store);

export default store;
