import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 24,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const NuevoEquipo = ({ campanas, lideres, handleClose, handleCrear }) => {
    const [nombre, setNombre] = useState(null);
    const [campana, setCampana] = useState(null);
    const [lider, setLider] = useState(null);

    const handleChangeNombre = (event) => {
        setNombre(event.target.value);
    };

    const handleSelectCampana = (event) => {
        setCampana(event.target.value);
    };

    const handleSelectLider = (event) => {
        setLider(event.target.value);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary">
                        Crear Nuevo Equipo
                    </Typography>
                </Box>
                <Grid container spacing={5} sx={{ mt: '2px' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="nombre"
                            label="Nombre"
                            placeholder="Nombre del equipo"
                            variant="outlined"
                            value={nombre}
                            onChange={handleChangeNombre}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="campana"
                            label="Campañas vigentes"
                            value={campana}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectCampana}>
                            {campanas &&
                                campanas.map((campana) => {
                                    return (
                                        <MenuItem
                                            key={campana.id}
                                            value={campana.id}>
                                            {campana.nombre}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="lider"
                            label="Lider"
                            value={lider}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectLider}>
                            {lideres &&
                                lideres.map((lider) => {
                                    return (
                                        <MenuItem
                                            key={lider.id}
                                            value={lider.id}>
                                            {lider.nombres} {lider.apellidos}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                handleCrear({
                                    nombre,
                                    campana,
                                    lider,
                                })
                            }>
                            Crear
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default NuevoEquipo;
