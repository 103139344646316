import React from 'react';
import { useRoles } from '../../../hooks/useRoles';

/**
 * @param {{
 * children: React.ReactNode
 * roles: ('Gerencia' | 'Director Administrativo' | 'Logística' | 'Impresión'| 'Gerencia Winner' | 'Director Operativo' | 'Promotor')[]
 * }}
 * @returns
 */
export default function RolesGate({ children, roles }) {
    const hasPermission = useRoles(...roles);
    return hasPermission ? children : <></>;
}
