import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import ResumenInventario from '../resumenInventario';
import EnviosInternos from '../enviosInternos';
import EnviosClientesFinales from '../enviosClientesFinales';
import OrdenesImpresion from '../ordenesInventario';
import { useLocation } from 'react-router-dom';

const TabsInventario = ({ pais }) => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(location.state?.tab || 0);

    const hanldeChangeTab = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Tabs
                    value={selectedTab}
                    onChange={hanldeChangeTab}
                    sx={{ pt: 1 }}>
                    <Tab label="Resumen Inventario" value={0} />
                    <Tab label="Ordenes Impresión" value={1} />
                    <Tab label="Envíos Internos" value={2} />
                    <Tab label="Envío y Entrega a Clientes Finales" value={3} />
                </Tabs>
                {selectedTab === 0 && (
                    <ResumenInventario pais={pais}></ResumenInventario>
                )}
                {selectedTab === 1 && (
                    <OrdenesImpresion pais={pais}></OrdenesImpresion>
                )}
                {selectedTab === 2 && (
                    <EnviosInternos pais={pais}></EnviosInternos>
                )}
                {selectedTab === 3 && (
                    <EnviosClientesFinales pais={pais}></EnviosClientesFinales>
                )}
            </Box>
        </>
    );
};

export default TabsInventario;
