import { useTheme } from '@emotion/react';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import OrdenImpresion from './nuevaOrdenImpresion';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ListHeader from './listHeader';

const OrdenesImpresion = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [, /* page */ setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);
    const [campanasVigentes, setCampanasVigentes] = useState([]);
    const [estadosOrdenImpresion, setEstadosOrdenImpresion] = useState([]);

    // Filtros
    const [filtros, setFiltros] = useState({
        numero: '',
        campanas: '',
        estado: '',
        fecha: '',
    });

    // Condicionales
    const [loading /* setLoading */] = useState(false);

    // Modal
    const [open, setOpen] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    // Re-render
    const [guardado, setGuardado] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();
    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        sendRequest(
            'get',
            `${URL}/${pais}/ordenes-impresion?numero=${filtros.numero}&campanas=${filtros.campanas}&estado=${filtros.estado}&fecha=${filtros.fecha}`,
        ).then((res) => {
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [
        URL,
        pais,
        guardado,
        filtros.numero,
        filtros.campanas,
        filtros.estado,
        filtros.fecha,
    ]);

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/campana/vigentes`).then((res) => {
            setCampanasVigentes(res.data);
        });
        sendRequest('get', `${URL}/${pais}/estado-orden-impresion`).then(
            (res) => {
                setEstadosOrdenImpresion(res.data);
            },
        );
    }, [URL, pais, guardado]);

    const columns = [
        {
            field: 'numero',
            headerName: 'Número',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.id,
        },
        {
            field: 'campana',
            headerName: 'Campaña',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.campana.nombre,
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad Ordenada',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cantidad,
        },
        {
            field: 'pendientes_por_validar',
            headerName: 'Pendientes por Validar',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.pendientes_por_validar,
        },
        {
            field: 'numeracion',
            headerName: 'Numeración',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.numeracion,
        },
        {
            field: 'observacion',
            headerName: 'Observaciones',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.observaciones,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.estado.nombre,
        },
        {
            field: 'fecha_creacion',
            headerName: 'Fecha de Solicitud',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => dayjs(row.created_at).format('DD/MM/YYYY'),
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.5,
            getActions: ({ row }) => {
                return [
                    row.estado.id === 1 ? (
                        <IconButton
                            color="primary"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() =>
                                navigate(
                                    '/app/inventario/orden-impresion/validar',
                                    {
                                        state: {
                                            orden: row,
                                        },
                                    },
                                )
                            }>
                            <CheckCircleOutline></CheckCircleOutline>
                            <Typography>Validar</Typography>
                        </IconButton>
                    ) : (
                        <></>
                    ),
                ];
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenCreate(false);
    };

    const handleCreateClick = () => {
        setOpen(true);
        setOpenCreate(true);
    };

    const handleCrear = async (newData) => {
        try {
            await sendRequest('post', `${URL}/${pais}/ordenes-impresion`, {
                ...newData,
            });
            handleClose();
            setGuardado(!guardado);
        } catch (error) {}
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}>
                            Ordenes de Impresión
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleCreateClick}>
                            Crear Nueva Orden de Impresión
                        </Button>
                    </Grid>
                </Grid>
                <ListHeader
                    campanas={campanasVigentes}
                    estadosOrdenImpresion={estadosOrdenImpresion}
                    filtros={filtros}
                    setFiltros={setFiltros}></ListHeader>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div>
                    {openCreate && (
                        <OrdenImpresion
                            pais={pais}
                            campanas={campanasVigentes}
                            handleCrear={handleCrear}
                            handleClose={handleClose}></OrdenImpresion>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default OrdenesImpresion;
