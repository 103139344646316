import {
    Box,
    Card,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    Checkbox,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({
    setSearchNombre,
    departamentos,
    departamentoSelected,
    setDepartamentoSelected,
    ciudades,
    setCiudadSelected,
    divisiones,
    setDivisionSelected,
    anos,
    setAnoSelected,
    canales,
    setCanalSelected,
    sectores,
    setSectorSelected,
    setVigente,
}) => {
    const vigencias = [
        { nombre: 'Vigente', value: true },
        { nombre: 'No Vigente', value: false },
    ];

    const handleChangeNombre = (e) => {
        setTimeout(() => {
            setSearchNombre(e.target.value);
        }, 2000);
    };

    const handleDepartamentoSelected = (e, newValue) => {
        const departamentosSelected = [];
        for (const value of newValue) {
            departamentosSelected.push(value.id);
        }
        setDepartamentoSelected(departamentosSelected);
        setCiudadSelected([]);
    };

    const handleCiudadSelected = (e, newValue) => {
        const ciudadesSelected = [];
        for (const value of newValue) {
            ciudadesSelected.push(value.id);
        }
        setCiudadSelected(ciudadesSelected);
    };

    const handleDivisionSelected = (e, newValue) => {
        const divisionesSelected = [];
        for (const value of newValue) {
            divisionesSelected.push(value.id);
        }
        setDivisionSelected(divisionesSelected);
    };

    const handleAnoSelected = (e, newValue) => {
        const anosSelected = [];
        for (const value of newValue) {
            anosSelected.push(value.ano);
        }
        setAnoSelected(anosSelected);
    };

    const handleCanalSelected = (e, newValue) => {
        const canalesSelected = [];
        for (const value of newValue) {
            canalesSelected.push(value.canal);
        }
        setCanalSelected(canalesSelected);
    };

    const handleSectorSelected = (e, newValue) => {
        const sectoresSelected = [];
        for (const value of newValue) {
            sectoresSelected.push(value.id);
        }
        setSectorSelected(sectoresSelected);
    };

    const handleChangeVigente = (e, newValue) => {
        setVigente(newValue ? newValue.value : '');
    };

    return (
        <Box sx={style} component="form">
            <Card
                variant="outlined"
                sx={{ backgroundColor: '#D9D9D9', width: '98%', mt: 1 }}>
                <Typography color="primary" variant="h6" sx={{ pl: 2, pb: 2 }}>
                    Filtros
                </Typography>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-around"
                    alignItems="flex-start">
                    <Grid item sm={12} md="auto">
                        <TextField
                            placeholder="Nombre de la Campaña"
                            size="small"
                            variant="standard"
                            sx={{
                                marginRight: '10px',
                                pl: 2,
                                mb: 2,
                            }}
                            onChange={handleChangeNombre}></TextField>
                    </Grid>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disablePortal
                        limitTags={1}
                        options={departamentos}
                        getOptionLabel={(option) => option.nombre}
                        onChange={handleDepartamentoSelected}
                        size="small"
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    sx={{
                                        ml: -1,
                                    }}
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    checked={selected}
                                />
                                {option.nombre}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder=""
                                variant="standard"
                                label="Departamento"
                            />
                        )}
                        ListboxProps={{
                            sx: {
                                maxHeight: 200,
                                overflow: 'auto',
                            },
                        }}
                        sx={{
                            minWidth: 170,
                            maxWidth: 170,
                            pb: 2,
                        }}></Autocomplete>
                    {departamentoSelected.length > 0 && (
                        <Autocomplete
                            multiple
                            limitTags={1}
                            options={ciudades}
                            getOptionLabel={(option) => option.nombre}
                            onChange={handleCiudadSelected}
                            size="small"
                            renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id}>
                                    <Checkbox
                                        sx={{
                                            ml: -1,
                                        }}
                                        icon={
                                            <CheckBoxOutlineBlankIcon fontSize="small" />
                                        }
                                        checkedIcon={
                                            <CheckBoxIcon fontSize="small" />
                                        }
                                        checked={selected}
                                    />
                                    {option.nombre}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Ciudad"
                                />
                            )}
                            ListboxProps={{
                                sx: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                },
                            }}
                            sx={{
                                minWidth: 170,
                                maxWidth: 170,
                                pb: 2,
                            }}></Autocomplete>
                    )}
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={divisiones}
                        getOptionLabel={(option) => option.nombre}
                        onChange={handleDivisionSelected}
                        size="small"
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    sx={{
                                        ml: -1,
                                    }}
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    checked={selected}
                                />
                                {option.nombre}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Division"
                            />
                        )}
                        ListboxProps={{
                            sx: {
                                maxHeight: 200,
                                overflow: 'auto',
                            },
                        }}
                        sx={{
                            minWidth: 150,
                            maxWidth: 150,
                            pb: 2,
                        }}></Autocomplete>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={anos}
                        getOptionLabel={(option) => option.ano}
                        onChange={handleAnoSelected}
                        size="small"
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    sx={{
                                        ml: -1,
                                    }}
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    checked={selected}
                                />
                                {option.ano}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Año"
                            />
                        )}
                        ListboxProps={{
                            sx: {
                                maxHeight: 200,
                            },
                        }}
                        sx={{
                            minWidth: 120,
                            maxWidth: 140,
                            pb: 3,
                        }}></Autocomplete>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={canales}
                        getOptionLabel={(option) => option.canal}
                        onChange={handleCanalSelected}
                        size="small"
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    sx={{
                                        ml: -1,
                                    }}
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    checked={selected}
                                />
                                {option.canal}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Canal"
                            />
                        )}
                        ListboxProps={{
                            sx: {
                                maxHeight: 200,
                                overflow: 'auto',
                            },
                        }}
                        sx={{
                            minWidth: 120,
                            maxWidth: 140,
                            pb: 2,
                        }}></Autocomplete>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        options={sectores}
                        getOptionLabel={(option) => option.sector}
                        onChange={handleSectorSelected}
                        size="small"
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    sx={{
                                        ml: -1,
                                    }}
                                    icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                    }
                                    checked={selected}
                                />
                                {option.sector}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Sector"
                            />
                        )}
                        ListboxProps={{
                            sx: {
                                maxHeight: 200,
                            },
                        }}
                        sx={{
                            minWidth: 180,
                            maxWidth: 180,
                            pb: 2,
                        }}></Autocomplete>
                    <Autocomplete
                        limitTags={1}
                        options={vigencias}
                        getOptionLabel={(option) => option.nombre}
                        onChange={handleChangeVigente}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Vigencia"
                            />
                        )}
                        ListboxProps={{
                            sx: {
                                maxHeight: 200,
                                overflow: 'auto',
                            },
                        }}
                        sx={{
                            minWidth: 120,
                            maxWidth: 120,
                            pb: 2,
                        }}></Autocomplete>
                </Grid>
            </Card>
        </Box>
    );
};

export default ListHeader;
