import {
    Box,
    Card,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({ campañas, searchCampana, setSearchCampana }) => {
    const handleCampanaSelected = (e, newValue) => {
        const campanasSelected = [];
        for (const value of newValue) {
            campanasSelected.push(value.id);
        }
        setSearchCampana([searchCampana[0], ...campanasSelected]);
    };

    return (
        <Box sx={style} component="form">
            <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item sm={4}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                            mt: 2,
                        }}>
                        <Typography
                            color="primary"
                            variant="h6"
                            sx={{ pl: 2, pb: 2 }}>
                            Filtrar por
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="flex-start">
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                disablePortal
                                limitTags={1}
                                options={campañas}
                                getOptionLabel={(option) => option.nombre}
                                onChange={handleCampanaSelected}
                                size="small"
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} key={option.id}>
                                        <Checkbox
                                            sx={{
                                                ml: -1,
                                            }}
                                            icon={
                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                                <CheckBoxIcon fontSize="small" />
                                            }
                                            checked={selected}
                                        />
                                        {option.nombre}
                                        {option.ciudad
                                            ? ` - ${option.ciudad.nombre}`
                                            : ''}{' '}
                                        -{' '}
                                        {dayjs(option.vigencia).format('YYYY')}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder=""
                                        variant="standard"
                                        label="Campaña"
                                    />
                                )}
                                ListboxProps={{
                                    sx: {
                                        maxHeight: 200,
                                        overflow: 'auto',
                                    },
                                }}
                                sx={{
                                    minWidth: 300,
                                    maxWidth: 300,
                                    pb: 2,
                                    ml: 5,
                                }}></Autocomplete>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
