import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
    DataGrid,
    GridToolbar,
    esES,
    getGridStringOperators,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { sendRequest } from '../../../../services/request';
import DetalleCliente from '../detalle';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import NuevoCliente from '../nuevo_cliente';
import { useTheme } from '@emotion/react';
import ListHeader from './listHeader';
import Historial from '../historial';
import { useLocation } from 'react-router-dom';

export default function ConsultaCliente({ pais }) {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);
    const [clientRow, setClientRow] = useState(null);
    const [open, setOpen] = useState(false);

    const [departamento, setDepartamento] = useState(null);

    //Condicionales
    const [isEditing, setIsEditing] = useState(false);
    const [verHistorial, setVerHistorial] = useState(false);
    const [loading, setLoading] = useState(false);

    // Listas
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [estadosCiviles, setEstadosCiviles] = useState([]);
    const [edadesHijos, setEdadesHijos] = useState([]);
    const [nivelDeEstudios, setNivelDeEstudios] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    // Campos Search
    const [searchId, setSearchId] = useState('');
    const [searchNombres, setSearchNombres] = useState('');
    const [searchApellidos, setSearchApellidos] = useState('');
    const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState('tipo');
    const [searchNumeroDoc, setSearchNumeroDoc] = useState('');
    const [generoSelected, setGeneroSelected] = useState('genero');
    const [searchCelular, setSearchCelular] = useState('');
    const [searchCorreo, setSearchCorreo] = useState('');
    const [tratamientoDatosSelected, setTratamientoDatosSelected] =
        useState('tratamiento');

    // Campos selected
    const [rowsSelected, setRowsSelected] = useState([]);

    const URL = `${process.env.REACT_APP_API_URL}`;
    const theme = useTheme();
    const location = useLocation();

    // Datos en el locations desde Campana
    const asignar = location.state?.asignar || '';
    const campana = location.state?.campana;

    //Modal
    const [openCreate, setOpenCreate] = useState(false);

    //Re-render
    const [guardado, setGuardado] = useState(false);

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/cliente?limit=${limit}&page=${page}&searchID=${searchId}&searchNombres=${searchNombres}&searchApellidos=${searchApellidos}&searchTipoDoc=${
                tipoDocumentoSelected === 'tipo' ? '' : tipoDocumentoSelected
            }&searchNumeroDoc=${searchNumeroDoc}&searchGenero=${
                generoSelected === 'genero' ? '' : generoSelected
            }&searchCelular=${searchCelular}&searchCorreo=${searchCorreo}&searchTratamientoDatos=${
                tratamientoDatosSelected === 'tratamiento'
                    ? ''
                    : tratamientoDatosSelected
            }&assigned=${asignar}&campana=${campana?.id || ''}`,
        ).then((res) => {
            setLoading(false);
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [
        limit,
        page,
        guardado,
        URL,
        searchId,
        searchNombres,
        searchApellidos,
        tipoDocumentoSelected,
        searchNumeroDoc,
        generoSelected,
        searchCelular,
        searchCorreo,
        tratamientoDatosSelected,
        pais,
        asignar,
        campana?.id,
    ]);

    /* useEffect(() => {
        if (asignar) {
            setLoading(true);
            sendRequest(
                'get',
                `${URL}/${pais}/cliente?limit=${limit}&page=${page}&searchID=${searchId}&searchNombres=${searchNombres}&searchApellidos=${searchApellidos}&searchTipoDoc=${
                    tipoDocumentoSelected === 'tipo'
                        ? ''
                        : tipoDocumentoSelected
                }&searchNumeroDoc=${searchNumeroDoc}&searchGenero=${
                    generoSelected === 'genero' ? '' : generoSelected
                }&searchCelular=${searchCelular}&searchCorreo=${searchCorreo}&searchTratamientoDatos=${
                    tratamientoDatosSelected === 'tratamiento'
                        ? ''
                        : tratamientoDatosSelected
                }`,
            ).then((res) => {
                setLoading(false);
                setRows(res.data.items);
                setRowCount(res.data.meta.totalItems);
            });
        }
    }, []); */

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/tipo-documentos`).then((res) => {
            setTipoDocumentos(res.data);
        });

        sendRequest('get', `${URL}/${pais}/genero`).then((res) =>
            setGeneros(res.data),
        );

        sendRequest('get', `${URL}/${pais}/estado-civil`).then((res) =>
            setEstadosCiviles(res.data),
        );

        sendRequest('get', `${URL}/${pais}/edades-hijos`).then((res) =>
            setEdadesHijos(res.data),
        );

        sendRequest('get', `${URL}/${pais}/estudios`).then((res) =>
            setNivelDeEstudios(res.data),
        );

        sendRequest('get', `${URL}/${pais}/departamentos`).then((res) =>
            setDepartamentos(res.data),
        );
    }, [URL, pais]);

    useEffect(() => {
        if (departamento) {
            sendRequest(
                'get',
                `${URL}/${pais}/ciudades?departamento=${departamento}`,
            ).then((res) => setCiudades(res.data));
        }
    }, [URL, pais, departamento]);

    const filterOperators = getGridStringOperators()
        .map((item) => {
            if (item.value === 'equals') {
                item.value = '=';
            }
            return item;
        })
        .filter(({ value }) => ['=', 'contains'].includes(value));

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'header',
            filterOperators,
            sortable: false,
        },
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 1,
            headerClassName: 'header',
            filterOperators,
            sortable: false,
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            filterOperators,
            sortable: false,
        },
        {
            field: 'tipo_documento',
            headerName: 'Tipo Doc.',
            headerClassName: 'header',
            filterOperators,
            sortable: false,
            align: 'center',
            valueGetter: ({ row }) => {
                return row.tipo_documento?.tipo;
            },
        },
        {
            field: 'numero_documento',
            headerName: 'Núm. Doc.',
            headerClassName: 'header',
            filterOperators,
            sortable: false,
        },
        {
            field: 'genero',
            headerName: 'Género',
            headerClassName: 'header',
            filterOperators,
            sortable: false,
            valueGetter: ({ row }) => {
                return row.genero?.genero;
            },
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            filterOperators,
            sortable: false,
        },
        {
            field: 'correo',
            headerName: 'Correo',
            flex: 1,
            headerClassName: 'header',
            filterOperators,
            sortable: false,
        },
        {
            field: 'tratamiento_datos',
            headerName: 'Trat. Datos',
            headerClassName: 'header',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                if (params.value) {
                    return <CheckIcon></CheckIcon>;
                } else {
                    return <ClearIcon></ClearIcon>;
                }
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleEditClick(row)}>
                        <EditIcon />
                        <Typography>Editar</Typography>
                    </IconButton>,
                    <IconButton
                        color="text"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleDetailsClick(row)}>
                        <ShortcutIcon />
                        <Typography>Ver Detalle</Typography>
                    </IconButton>,
                ];
            },
        },
    ];

    if (asignar) {
        columns.pop();
    }

    const handleEditClick = (row) => {
        setClientRow(row);
        setOpen(true);
        setIsEditing(true);
    };

    const handleDetailsClick = (row) => {
        setClientRow(row);
        setOpen(true);
    };

    const handleCreateClick = () => {
        setOpen(true);
        setOpenCreate(true);
    };

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleGuardar = async (updatedData) => {
        try {
            await sendRequest(
                'put',
                `${URL}/${pais}/cliente?id=${updatedData?.id}`,
                updatedData,
            );
            setOpen(false);
            setGuardado(!guardado);
        } catch (error) {}
    };

    const handleCrear = async (newData) => {
        try {
            await sendRequest('post', `${URL}/${pais}/cliente`, newData);
            handleClose();
            setGuardado(!guardado);
        } catch (error) {}
    };

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false);
        setVerHistorial(false);
        setOpenCreate(false);
        setDepartamento(null);
    };

    const handleAtras = () => {
        setVerHistorial(false);
    };

    const handleVerHistorial = () => {
        setVerHistorial(true);
    };

    const handleAsignar = async () => {
        if (rowsSelected.length > 0) {
            await sendRequest('post', `${URL}/${pais}/clientes-campanas`, {
                clientes: rowsSelected,
                campana: campana.id,
            });
            setGuardado(!guardado);
        }
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}>
                            Clientes
                        </Typography>
                    </Grid>
                    {asignar && (
                        <Grid item xs={12} md={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleAsignar}>
                                Asignar Clientes
                            </Button>
                        </Grid>
                    )}
                    {!asignar && (
                        <Grid item xs={12} md={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleCreateClick}>
                                Crear Cliente Nuevo
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <ListHeader
                    setSearchId={setSearchId}
                    setSearchNombres={setSearchNombres}
                    setSearchApellidos={setSearchApellidos}
                    tipoDocumentos={tipoDocumentos}
                    tipoDocumentoSelected={tipoDocumentoSelected}
                    setTipoDocumentoSelected={setTipoDocumentoSelected}
                    setSearchNumeroDoc={setSearchNumeroDoc}
                    generos={generos}
                    generoSelected={generoSelected}
                    setGeneroSelected={setGeneroSelected}
                    setSearchCelular={setSearchCelular}
                    setSearchCorreo={setSearchCorreo}
                    tratamientoDatosSelected={tratamientoDatosSelected}
                    setTratamientoDatosSelected={
                        setTratamientoDatosSelected
                    }></ListHeader>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    checkboxSelection={asignar}
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    onRowSelectionModelChange={(newRowSelection) => {
                        setRowsSelected(newRowSelection);
                    }}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div>
                    {clientRow && !verHistorial && (
                        <DetalleCliente
                            handleVerHistorial={handleVerHistorial}
                            handleClose={handleClose}
                            clientRow={clientRow}
                            setClientRow={setClientRow}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            handleGuardar={handleGuardar}
                            tipoDocumentos={tipoDocumentos}
                            generos={generos}
                            estadosCiviles={estadosCiviles}
                            edadesHijosList={edadesHijos}
                            nivelDeEstudiosList={nivelDeEstudios}
                            departamentosList={departamentos}
                            departamento={departamento}
                            setDepartamento={setDepartamento}
                            ciudadesList={ciudades}></DetalleCliente>
                    )}
                    {openCreate && (
                        <NuevoCliente
                            handleCrear={handleCrear}
                            handleClose={handleClose}
                            tipoDocumentos={tipoDocumentos}
                            generos={generos}
                            estadosCiviles={estadosCiviles}
                            edadesHijosList={edadesHijos}
                            nivelDeEstudiosList={nivelDeEstudios}
                            departamentosList={departamentos}
                            departamento={departamento}
                            setDepartamento={setDepartamento}
                            ciudadesList={ciudades}></NuevoCliente>
                    )}
                    {verHistorial && (
                        <Historial
                            handleClose={handleClose}
                            clientRow={clientRow}
                            handleAtras={handleAtras}
                            pais={pais}></Historial>
                    )}
                </div>
            </Modal>
        </>
    );
}
