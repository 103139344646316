import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import { useTheme } from '@emotion/react';
import { thousandFormatter } from '../../../../utils/utils';
import ListHeader from '../../campaña/consulta/listHeader';
import dayjs from 'dayjs';
import NuevaCampana from '../nueva_campana';
import EditarCampana from '../editar';
import { useNavigate } from 'react-router-dom';
import { PersonAdd, Edit, Shortcut } from '@mui/icons-material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import CustomFooter from './customFooter';

const ConsultaCampana = ({ pais }) => {
    const [campanas, setCampanas] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [divisiones, setDivisiones] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [anos] = useState([
        {
            id: 1,
            ano: '2015',
        },
        {
            id: 2,
            ano: '2016',
        },
        {
            id: 3,
            ano: '2017',
        },
        {
            id: 4,
            ano: '2018',
        },
        {
            id: 5,
            ano: '2019',
        },
        {
            id: 6,
            ano: '2020',
        },
        {
            id: 7,
            ano: '2021',
        },
        {
            id: 8,
            ano: '2022',
        },
        {
            id: 9,
            ano: '2023',
        },
        {
            id: 10,
            ano: '2024',
        },
    ]);
    const [canales /* setCanales */] = useState([
        {
            id: 1,
            canal: 'TMK',
        },
        {
            id: 2,
            canal: 'F2F',
        },
        {
            id: 3,
            canal: 'TMK y F2F',
        },
    ]);
    const [sectores, setSectores] = useState([]);
    const [campanaRow, setCampanaRow] = useState(null);
    const [total, setTotal] = useState(0);

    // Campos Filtrar
    const [searchNombre, setSearchNombre] = useState('');
    const [departamentoSelected, setDepartamentoSelected] = useState([]);
    const [ciudadSelected, setCiudadSelected] = useState([]);
    const [divisionSelected, setDivisionSelected] = useState([]);
    const [anoSelected, setAnoSelected] = useState([]);
    const [canalSelected, setCanalSelected] = useState([]);
    const [sectorSelected, setSectorSelected] = useState([]);
    const [vigente, setVigente] = useState('');

    // Modal
    const [open, setOpen] = useState(false);
    const [openNuevaCampana, setOpenNuevaCampana] = useState(false);
    const [openEditCampana, setOpenEditCampana] = useState(false);

    // Condicionales
    const [loading, setLoading] = useState(false);
    const [recargar, setRecargar] = useState(false);

    const URL = process.env.REACT_APP_API_URL;
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/campana?limit=${limit}&page=${page}&searchNombre=${searchNombre}&searchCiudad=${
                ciudadSelected === 'ciudad' ? '' : ciudadSelected
            }&searchDivision=${
                divisionSelected === 'division' ? '' : divisionSelected
            }&searchAno=${
                anoSelected === 'ano' ? '' : anoSelected
            }&searchEstrategia=${
                canalSelected === 'canal' ? '' : canalSelected
            }&searchSector=${
                sectorSelected === 'sector' ? '' : sectorSelected
            }&vigente=${vigente === 'vigencia' ? '' : vigente}`,
        ).then((res) => {
            setLoading(false);
            /* res.data.items.map((item) => (item.expanded = false)); */
            setCampanas(res.data.items);
            setCount(res.data.meta.totalItems);
            setTotal(res.data.total);
        });
    }, [
        URL,
        pais,
        limit,
        page,
        searchNombre,
        ciudadSelected,
        divisionSelected,
        anoSelected,
        canalSelected,
        sectorSelected,
        recargar,
        vigente,
    ]);

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/division`).then((res) => {
            setDivisiones(res.data);
        });
        sendRequest('get', `${URL}/${pais}/sector`).then((res) => {
            setSectores(res.data);
        });
        sendRequest('get', `${URL}/${pais}/departamentos`).then((res) => {
            setDepartamentos(res.data);
        });
    }, [URL, pais]);

    useEffect(() => {
        if (departamentoSelected) {
            sendRequest(
                'get',
                `${URL}/${pais}/ciudades?departamento=${departamentoSelected}`,
            ).then((res) => {
                setCiudades(res.data);
            });
        }
    }, [URL, pais, departamentoSelected]);

    const columns = [
        {
            field: 'nombre',
            headerName: 'Nombre',
            headerClassName: 'header',
            sortable: false,
            flex: 2,
        },
        {
            field: 'ciudad',
            headerName: 'Ciudad',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.ciudad?.nombre;
            },
        },
        {
            field: 'division',
            headerName: 'Division',
            headerClassName: 'header',
            sortable: false,
            flex: 1.5,
            renderCell: ({ row }) => {
                return (
                    <Chip
                        label={row.division?.nombre}
                        color={
                            row.division?.id === 1
                                ? 'publicidad'
                                : 'fundraising'
                        }
                    />
                );
            },
        },
        {
            field: 'sector',
            headerName: 'Sector',
            headerClassName: 'header',
            sortable: false,
            flex: 1.2,
            renderCell: ({ row }) => {
                return (
                    <Chip
                        label={row.sector?.sector}
                        color={
                            row.sector?.id === 1
                                ? 'secondary'
                                : row.sector?.id === 2
                                ? 'automotriz'
                                : row.sector?.id === 3
                                ? 'entretenimiento'
                                : row.sector?.id === 4
                                ? 'hyt'
                                : row.sector?.id === 5
                                ? 'bienestar'
                                : 'secondary'
                        }
                    />
                );
            },
        },
        {
            field: 'estrategia',
            headerName: 'Canal',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Chip
                        label={row.estrategia}
                        color={
                            row.estrategia && row.estrategia === 'F2F'
                                ? 'f2f'
                                : 'tmk'
                        }
                        style={
                            row.estrategia === 'TMK y F2F'
                                ? {
                                      background:
                                          'linear-gradient(to right, #05F247, #A605F2)',
                                  }
                                : undefined
                        }
                    />
                );
            },
        },
        {
            field: 'vigencia',
            headerName: 'Vigencia',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return dayjs(row.vigencia).format('DD/MM/YYYY');
            },
        },
        {
            field: 'clientes_validos',
            headerName: 'Total Donantes/Clientes',
            headerClassName: 'header',
            sortable: false,
            flex: 1.2,
            align: 'center',
            renderCell: ({ row }) => {
                return thousandFormatter.format(row.total_tarjetones_vendidos);
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.8,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleEditClick(row)}>
                        <Edit />
                        <Typography>Editar</Typography>
                    </IconButton>,
                    <IconButton
                        color="text"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleVerBDD(row)}>
                        <Shortcut />
                        <Typography>Ver BD</Typography>
                    </IconButton>,
                    dayjs(row.vigencia) < dayjs(new Date()) ? (
                        <></>
                    ) : (
                        <IconButton
                            color="text"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() => handleAsignar(row)}>
                            <PersonAdd />
                            <Typography>Asignar</Typography>
                        </IconButton>
                    ),
                ];
            },
        },
    ];

    const handleVerBDD = (row) => {
        navigate('/app/campana/clientes', { state: { campana: row } });
    };

    const handleAsignar = (row) => {
        navigate('/app/campana/clientes/asignar', {
            state: { campana: row, asignar: true },
        });
    };

    /* const handleExpandClick = (campanaId) => {
        const updatedCampanas = campanas.map((campana) => {
            if (campana.id === campanaId) {
                campana.expanded = !campana.expanded;
            }
            return campana;
        });
        setCampanas(updatedCampanas);
    }; */

    const handleRowsPerPage = (event) => {
        setLimit(event.target.value);
    };

    const handleCreateClick = () => {
        setOpen(!open);
        setOpenNuevaCampana(!openNuevaCampana);
        setOpenEditCampana(false);
    };

    const handleEditClick = (campanaSelected) => {
        setOpen(!open);
        setOpenEditCampana(!openEditCampana);
        setOpenNuevaCampana(false);
        setCampanaRow(campanaSelected);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenNuevaCampana(false);
        setOpenEditCampana(false);
    };

    const handlePaginationChange = (event, page) => {
        setPage(page);
    };

    const handleAgregar = async (newData) => {
        try {
            await sendRequest('post', `${URL}/${pais}/campana`, newData);
            handleClose();
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditar = async (id, editData) => {
        try {
            await sendRequest('put', `${URL}/${pais}/campana/${id}`, editData);
            handleClose();
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}>
                            Campañas
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleCreateClick}>
                            Crear nueva campaña
                        </Button>
                    </Grid>
                </Grid>
                <ListHeader
                    setSearchNombre={setSearchNombre}
                    departamentos={departamentos}
                    departamentoSelected={departamentoSelected}
                    setDepartamentoSelected={setDepartamentoSelected}
                    ciudades={ciudades}
                    setCiudadSelected={setCiudadSelected}
                    divisiones={divisiones}
                    setDivisionSelected={setDivisionSelected}
                    anos={anos}
                    setAnoSelected={setAnoSelected}
                    canales={canales}
                    setCanalSelected={setCanalSelected}
                    sectores={sectores}
                    setSectorSelected={setSectorSelected}
                    vigente={vigente}
                    setVigente={setVigente}></ListHeader>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={campanas ? campanas : []}
                    columns={columns}
                    paginationMode="server"
                    rowCount={count ? count : 0}
                    onPaginationModelChange={handlePaginationChange}
                    slots={{
                        toolbar: GridToolbar,
                        footer: CustomFooter,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                        footer: {
                            limit,
                            page,
                            count: count,
                            handlePaginationChange,
                            total,
                            handleRowsPerPage,
                        },
                    }}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}></DataGrid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div>
                    {openNuevaCampana && (
                        <NuevaCampana
                            deprtamentos={departamentos}
                            ciudades={ciudades}
                            divisiones={divisiones}
                            canales={canales}
                            sectores={sectores}
                            pais={pais}
                            handleClose={handleClose}
                            handleAgregar={handleAgregar}></NuevaCampana>
                    )}
                    {openEditCampana && (
                        <EditarCampana
                            campanaRow={campanaRow}
                            divisiones={divisiones}
                            canales={canales}
                            sectores={sectores}
                            pais={pais}
                            handleClose={handleClose}
                            handleEditar={handleEditar}></EditarCampana>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default ConsultaCampana;
