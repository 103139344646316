import { useTheme } from '@emotion/react';
import {
    Autocomplete,
    Box,
    Checkbox,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

dayjs.extend(weekOfYear);

const EnviosClientesFinales = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);

    const [numTarjetonValues, setNumTarjetonValues] = useState([]);

    //Condicionales
    const [loading /* setLoading */] = useState(false);
    const [limitReached, setLimitReached] = useState(false);

    const theme = useTheme();
    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        sendRequest(
            'get',
            `${URL}/${pais}/envio?page=${page}&limit=${limit}`,
        ).then((res) => {
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [URL, pais, page, limit]);

    const handleChange = (newValues, limit) => {
        setNumTarjetonValues(newValues);
        setLimitReached(newValues.length >= limit);
    };

    const columns = [
        {
            field: 'numero',
            headerName: 'Número',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.id,
        },
        {
            field: 'no_tarjeton',
            headerName: '# Tarjeton / Tarjeta',
            flex: 2,
            headerClassName: 'header',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => (
                /* row.tarjetones[0]?.numero ||  */ <Autocomplete
                    multiple
                    disablePortal
                    fullWidth
                    limitTags={row.cantidad}
                    value={row.contactabilidad}
                    options={row.campana.tarjetones}
                    getOptionLabel={(option) => option.numero}
                    getOptionDisabled={(option) =>
                        limitReached && !numTarjetonValues.includes(option)
                    }
                    size="small"
                    onChange={(event, newValues) =>
                        handleChange(newValues, row.cantidad)
                    }
                    renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.id}>
                            <Checkbox
                                sx={{
                                    ml: -1,
                                }}
                                icon={<CheckBoxOutlineBlank fontSize="small" />}
                                checkedIcon={<CheckBox fontSize="small" />}
                                checked={selected}
                            />
                            {option.numero}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder=""
                            variant="standard"
                        />
                    )}></Autocomplete>
            ),
        },
        {
            field: 'campana',
            headerName: 'Campaña',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.campana?.nombre,
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) =>
                `${row.cliente?.nombres} ${
                    row.cliente.apellidos ? row.cliente.apellidos : ''
                }`,
        },
        {
            field: 'destino',
            headerName: 'Destino',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente?.direccion_exacta,
        },
        {
            field: 'Cantidad',
            headerName: 'Cantidad',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.cantidad,
        },
        {
            field: 'no_guia',
            headerName: 'No. de Guía',
            flex: 1,
            headerClassName: 'header',
            editable: true,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => row.numero_guia,
        },
        {
            field: 'empresa_envio',
            headerName: 'Empresa Envío',
            flex: 1,
            headerClassName: 'header',
            editable: true,
            sortable: false,
            renderCell: ({ row }) => row.empresa_envio,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.estado?.estado,
        },
        {
            field: 'semana_preventa',
            headerName: 'Semana Preventa',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => dayjs(row.created_at).week(),
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Typography
                    variant="h4"
                    color="primary"
                    sx={{
                        textAlign: 'start',
                        marginLeft: '10px',
                        marginTop: '20px',
                    }}>
                    Envío y Entrega a Clientes Finales
                </Typography>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default EnviosClientesFinales;
