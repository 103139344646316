import {
    Box,
    Card,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '10px',
};

const ListHeader = ({ filtros, setFiltros, contactabilidades }) => {
    const handleChangeSearchNombres = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchNombres: e.target.value });
        }, 2000);
    };

    const handleChangeSearchApellidos = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchApellidos: e.target.value });
        }, 2000);
    };

    const handleChangeSearchCelular = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchCelular: e.target.value });
        }, 2000);
    };

    const handleSelectContactabilidad = (e) => {
        setFiltros({ ...filtros, contactabilidad: e.target.value });
    };
    return (
        <Box sx={style} component="form">
            <Grid container pt={1}>
                <Grid item xs={12} md={9}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                            width: '85%',
                            mt: 1,
                        }}>
                        <Grid
                            container
                            py={2}
                            px={2}
                            alignItems="flex-start"
                            justifyContent="space-between">
                            <Grid item xs={12} md={12}>
                                <Typography
                                    color="primary"
                                    variant="h6"
                                    sx={{ pb: 2 }}>
                                    Buscar
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md="auto">
                                <TextField
                                    placeholder="Nombres"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchNombres
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={2.5}>
                                <TextField
                                    placeholder="Apellidos"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchApellidos
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={2.4}>
                                <TextField
                                    placeholder="Celular"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchCelular
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={2.4}>
                                <TextField
                                    select
                                    size="small"
                                    value={filtros?.contactabilidad}
                                    variant="standard"
                                    onChange={handleSelectContactabilidad}>
                                    <MenuItem value="contactabilidad">
                                        Contactabilidad
                                    </MenuItem>
                                    {contactabilidades.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}>
                                                {item.nombre}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
