import { useSelector } from 'react-redux';
import { ROLES } from '../components/utils-components/permissions/permissions-map';

/**
 * @param  {...'Gerencia' | 'Director Administrativo' | 'Logística' | 'Impresión'| 'Gerencia Winner' | 'Director Operativo' | 'Promotor'} roles
 * @returns {boolean}
 */
export const useRoles = (...roles) => {
    const role = useSelector((state) => state.auth.role);
    return roles.includes(role);
};

/**
 * @returns {{isGerenciaEffective: boolean, isDirectorAdministrativo: boolean, isLogistica: boolean, , isImpresion: boolean, isGerenciaWinner: boolean, isDirectorOperativo: boolean, isPromotor: boolean}}
 */
export const useRoleValidation = () => {
    const role = useSelector((state) => state.auth.role);

    const roles = {
        isGerenciaEffective: role === ROLES.GERENCIA_EFFECTIVE,
        isDirectorAdministrativo: role === ROLES.DIRECTOR_ADMINISTRATIVO,
        isLogistica: role === ROLES.LOGISTICA,
        isImpresion: role === ROLES.IMPRESION,
        isGerenciaWinner: role === ROLES.GERENCIA_WINNER,
        isDirectorOperativo: role === ROLES.DIRECTOR_OPERATIVO,
        isPromotor: role === ROLES.PROMOTOR,
    };

    return roles;
};
