import axios from 'axios';
import store from '../redux/store';
import {
    isTokenExpired,
    logOut,
    refreshCognitoToken,
} from './CognitoAuthService';
import { authenticated, signOutSuccess } from '../redux/actions/auth';

const removeToken = async () => {
    await logOut();
    store.dispatch(signOutSuccess());
    /* signOutSuccess(); */
};

const updateTokens = (response) => {
    store.dispatch(
        authenticated(
            response.getIdToken().getJwtToken(),
            response.getRefreshToken().getToken(),
            response.getAccessToken().getJwtToken(),
        ),
    );
    return response.getIdToken().getJwtToken();
};

export const sendRequest = async (callMethod, url, data) => {
    const state = store.getState();
    const localState = JSON.parse(localStorage.getItem('state')).state;

    let refreshToken = state?.auth?.refreshToken
        ? state?.auth?.refreshToken
        : localState?.auth?.refreshToken;
    let newToken = state.auth.accessToken
        ? state?.auth?.accessToken
        : localState?.auth?.accessToken;

    if (isTokenExpired(newToken)) {
        newToken = await refreshCognitoToken(
            refreshToken,
            updateTokens,
            removeToken,
        );
    }

    const response = await axios({
        method: callMethod,
        url: url,
        headers: {
            Authorization: `Bearer ${newToken}`,
        },
        data: data,
    });

    return response;
};
