import { useTheme } from '@emotion/react';
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Check, Clear, Edit, Event, Shortcut } from '@mui/icons-material';
import { sendRequest } from '../../../../services/request';
import DetalleCliente from './detalle';
import Historial from './historial';
import NuevoCliente from './nuevo_cliente';
import Preventa from './preventa';
import ListHeader from './listHeader';

const MiBD = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);
    const [clientRow, setClientRow] = useState(null);
    const [open, setOpen] = useState(false);

    const [departamento, setDepartamento] = useState(null);

    //Condicionales
    const [isEditing, setIsEditing] = useState(false);
    const [verHistorial, setVerHistorial] = useState(false);
    const [loading, setLoading] = useState(false);

    // Listas
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [estadosCiviles, setEstadosCiviles] = useState([]);
    const [edadesHijos, setEdadesHijos] = useState([]);
    const [nivelDeEstudios, setNivelDeEstudios] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades /* setCiudades */] = useState([]);
    const [contactabilidades, setContactabilidades] = useState([]);
    const [mediosPago, setMediosPago] = useState([]);

    // Filtros
    const [filtros, setFiltros] = useState({
        searchNombres: '',
        searchApellidos: '',
        searchCelular: '',
        contactabilidad: 'contactabilidad',
    });

    const URL = `${process.env.REACT_APP_API_URL}`;
    const theme = useTheme();
    const location = useLocation();

    // Datos en el locations desde Campana
    const campanaId = location.state?.campanaId;

    //Modal
    const [openCreate, setOpenCreate] = useState(false);
    const [openPreventa, setOpenPreventa] = useState(false);

    //Re-render
    const [guardado, setGuardado] = useState(false);

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/clientes-asignados/campana/${campanaId}/promotor/2?page=${page}&limit=${limit}&searchNombres=${
                filtros.searchNombres
            }&searchApellidos=${filtros.searchApellidos}&searchCelular=${
                filtros.searchCelular
            }&contactabilidad=${
                filtros.contactabilidad !== 'contactabilidad'
                    ? filtros.contactabilidad
                    : ''
            }`,
        )
            .then((res) => {
                setLoading(false);
                setRows(res.data.items);
                setRowCount(res.data.meta.totalItems);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [
        URL,
        pais,
        campanaId,
        limit,
        page,
        guardado,
        filtros.searchNombres,
        filtros.searchApellidos,
        filtros.searchCelular,
        filtros.contactabilidad,
    ]);

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/contactabilidad`).then((res) =>
            setContactabilidades(res.data),
        );
        sendRequest('get', `${URL}/${pais}/tipo-documentos`).then((res) => {
            setTipoDocumentos(res.data);
        });

        sendRequest('get', `${URL}/${pais}/genero`).then((res) =>
            setGeneros(res.data),
        );

        sendRequest('get', `${URL}/${pais}/estado-civil`).then((res) =>
            setEstadosCiviles(res.data),
        );

        sendRequest('get', `${URL}/${pais}/estado-civil`).then((res) =>
            setEdadesHijos(res.data),
        );

        sendRequest('get', `${URL}/${pais}/edades-hijos`).then((res) =>
            setEdadesHijos(res.data),
        );

        sendRequest('get', `${URL}/${pais}/estudios`).then((res) =>
            setNivelDeEstudios(res.data),
        );

        sendRequest('get', `${URL}/${pais}/departamentos`).then((res) =>
            setDepartamentos(res.data),
        );
        sendRequest('get', `${URL}/${pais}/medio-pago`).then((res) =>
            setMediosPago(res.data),
        );
    }, [URL, pais]);

    const columns = [
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => row.cliente?.apellidos,
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.celular,
        },
        {
            field: 'whatsapp',
            headerName: 'WhatsApp',
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => {
                if (row.cliente.celular) {
                    return <Check></Check>;
                } else {
                    return <Clear></Clear>;
                }
            },
        },
        {
            field: 'contactabilidad',
            headerName: 'Contactabilidad',
            headerClassName: 'header',
            align: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Autocomplete
                        disablePortal
                        fullWidth
                        value={row.contactabilidad}
                        options={contactabilidades}
                        getOptionLabel={(option) => option.nombre}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder=""
                                variant="standard"
                            />
                        )}
                        readOnly={row.contactabilidad.id === 11}
                        onChange={(element, value) => {
                            if (value.id === 11) {
                                setOpen(true);
                                setOpenPreventa(true);
                                setClientRow(row);
                            } else {
                                handleContactabilidadSelect(
                                    row.cliente.id,
                                    row.campana.id,
                                    row.equipo,
                                    value.id,
                                );
                            }
                        }}></Autocomplete>
                );
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.5,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleEditClick(row.cliente)}>
                        <Edit />
                        <Typography>Editar</Typography>
                    </IconButton>,
                    <IconButton
                        color="text"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleDetailsClick(row.cliente)}>
                        <Shortcut />
                        <Typography>Ver Detalle</Typography>
                    </IconButton>,
                    <IconButton
                        color="text"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleDetailsClick(row.cliente)}>
                        <Event />
                        <Typography>Crear Evento</Typography>
                    </IconButton>,
                ];
            },
        },
    ];

    const handleContactabilidadSelect = async (
        clienteId,
        campanaId,
        equipoId,
        contactabilidad,
    ) => {
        const res = await sendRequest(
            'patch',
            `${URL}/${pais}/clientes-asignados/contactabilidad`,
            {
                cliente: clienteId,
                campana: campanaId,
                equipo: equipoId,
                contactabilidad,
            },
        );
        if (res.data.affected > 0) {
            setGuardado(!guardado);
        }
    };

    const handleEditClick = (row) => {
        setClientRow(row);
        setOpen(true);
        setIsEditing(true);
    };

    const handleDetailsClick = (row) => {
        setClientRow(row);
        setOpen(true);
    };

    const handleCreateClick = () => {
        setOpen(true);
        setOpenCreate(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false);
        setVerHistorial(false);
        setOpenCreate(false);
        setOpenPreventa(false);
        setDepartamento(null);
    };

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleVerHistorial = () => {
        setVerHistorial(true);
    };

    const handleAtras = () => {
        setVerHistorial(false);
    };

    const handleCrear = async (newData) => {
        try {
            await sendRequest('post', `${URL}/${pais}/cliente`, newData);
            handleClose();
            setGuardado(!guardado);
        } catch (error) {}
    };

    const handleGuardar = async (updatedData) => {
        try {
            await sendRequest(
                'put',
                `${URL}/${pais}/cliente/${updatedData?.id}`,
                updatedData,
            );
            setOpen(false);
            setGuardado(!guardado);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAgendar = async (dataAgendar) => {
        try {
            await sendRequest('post', `${URL}/${pais}/envio`, dataAgendar);
            setOpen(false);
            setGuardado(!guardado);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}>
                            Clientes por Contactar
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleCreateClick}>
                            Cliente Nuevo
                        </Button>
                    </Grid>
                </Grid>
                <ListHeader
                    filtros={filtros}
                    setFiltros={setFiltros}
                    contactabilidades={contactabilidades}></ListHeader>
                <DataGrid
                    getRowId={(row) => row.cliente.id}
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div>
                    {clientRow && !verHistorial && (
                        <DetalleCliente
                            handleVerHistorial={handleVerHistorial}
                            handleClose={handleClose}
                            clientRow={clientRow}
                            setClientRow={setClientRow}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            handleGuardar={handleGuardar}
                            tipoDocumentos={tipoDocumentos}
                            generos={generos}
                            estadosCiviles={estadosCiviles}
                            edadesHijosList={edadesHijos}
                            nivelDeEstudiosList={nivelDeEstudios}
                            departamentosList={departamentos}
                            departamento={departamento}
                            setDepartamento={setDepartamento}
                            ciudadesList={ciudades}></DetalleCliente>
                    )}
                    {openCreate && (
                        <NuevoCliente
                            handleCrear={handleCrear}
                            handleClose={handleClose}
                            tipoDocumentos={tipoDocumentos}
                            generos={generos}
                            estadosCiviles={estadosCiviles}
                            edadesHijosList={edadesHijos}
                            nivelDeEstudiosList={nivelDeEstudios}
                            departamentosList={departamentos}
                            departamento={departamento}
                            setDepartamento={setDepartamento}
                            ciudadesList={ciudades}></NuevoCliente>
                    )}
                    {verHistorial && (
                        <Historial
                            handleClose={handleClose}
                            clientRow={clientRow}
                            handleAtras={handleAtras}
                            pais={pais}></Historial>
                    )}
                    {openPreventa && (
                        <Preventa
                            cliente={clientRow}
                            mediosPago={mediosPago}
                            handleClose={handleClose}
                            handleAgendar={handleAgendar}></Preventa>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default MiBD;
