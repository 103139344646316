import {
    Box,
    Button,
    Grid,
    IconButton,
    /* LinearProgress, */
    Modal,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
/* import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'; */
import NuevoEquipo from '../nuevo_equipo';
import EditarEquipo from '../editar';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useTheme } from '@emotion/react';
import EditIcon from '@mui/icons-material/Edit';
import { PersonAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ListHeader from './listHeader';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

/* const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
})); */

/* function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    variant="body2"
                    color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
} */

const ConsultaEquipo = ({ pais }) => {
    const [limit /* setLimit */] = useState(10);
    const [, /* page */ setPage] = useState(0);
    const [rowCount /* setRowCount */] = useState(0);

    // Listas
    const [equipos, setEquipos] = useState([]);
    const [campanas, setCampanas] = useState([]);
    const [lideres, setLideres] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);

    // Filtros
    const [filtros, setFiltros] = useState({
        equipo: '',
        lideres: '',
        campanas: '',
        departamentos: '',
        ciudades: '',
    });

    // Modal
    const [open, setOpen] = useState(false);
    const [openNuevoEquipo, setOpenNuevoEquipo] = useState(false);
    const [openEditEquipo, setOpenEditEquipo] = useState(false);

    // Condicionales
    const [loading, setLoading] = useState(false);
    const [recargar, setRecargar] = useState(false);

    const URL = process.env.REACT_APP_API_URL;
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/equipos?nombre=${filtros.equipo}&lideres=${filtros.lideres}&campanas=${filtros.campanas}&ciudades=${filtros.ciudades}`,
        ).then((res) => {
            setLoading(false);
            // Calculo la cantidad de clientes asignados en la semana mas reciente
            res.data.map((equipo) => {
                if (equipo.clientes_asignados) {
                    const dato = equipo.clientes_asignados.filter(
                        (item) =>
                            dayjs(item.created_at).week() ===
                            equipo.clientes_asignados[0]
                                .cliente_equipo_current_week,
                    );
                    equipo.clientesAsignadosPorSemana = dato.length;
                }
                return equipo;
            });
            setEquipos(res.data);
        });
    }, [
        URL,
        pais,
        recargar,
        filtros.equipo,
        filtros.lideres,
        filtros.campanas,
        filtros.ciudades,
    ]);

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/campana?vigente=${true}`).then(
            (res) => {
                setCampanas(res.data.items);
            },
        );
        sendRequest('get', `${URL}/usuario/lider`).then((res) => {
            setLideres(res.data);
        });
        sendRequest('get', `${URL}/${pais}/departamentos`).then((res) => {
            setDepartamentos(res.data);
        });
    }, [URL, pais]);

    useEffect(() => {
        if (filtros.departamentos) {
            sendRequest(
                'get',
                `${URL}/${pais}/ciudades?departamento=${filtros.departamentos}`,
            ).then((res) => {
                setCiudades(res.data);
            });
        }
    }, [URL, pais, filtros.departamentos]);

    const columns = [
        {
            field: 'nombre',
            headerName: 'Equipo',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
        },
        {
            field: 'lider',
            headerName: 'Lider',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return `${row.lider?.nombres} ${row.lider?.apellidos}`;
            },
        },
        {
            field: 'campana',
            headerName: 'Campaña',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.campana?.nombre;
            },
        },
        {
            field: 'ciudad',
            headerName: 'Ciudad',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.campana?.ciudad?.nombre;
            },
        },
        {
            field: 'clientes_asignados',
            headerName: 'Clientes Asignados',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            align: 'center',
            renderCell: ({ row }) => row.clientesAsignadosPorSemana,
        },
        {
            field: 'semana_asignacion',
            headerName: 'Semana de Asignación',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            align: 'center',
            renderCell: ({ row }) =>
                row.clientes_asignados[0]
                    ? row.clientes_asignados[0].cliente_equipo_current_week
                    : 'No hay datos asignados',
        },
        {
            field: 'datos',
            headerName: 'Total Clientes/Donantes Asignados',
            headerClassName: 'header',
            sortable: false,
            flex: 1.2,
            align: 'center',
            renderCell: ({ row }) => {
                return row.clientes_asignados.length;
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleEditClick(row)}>
                        <EditIcon />
                        <Typography>Editar</Typography>
                    </IconButton>,
                    <IconButton
                        color="text"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleAsignar(row)}>
                        <PersonAdd />
                        <Typography>Asignar</Typography>
                    </IconButton>,
                ];
            },
        },
    ];

    const handleCreateClick = () => {
        setOpen(!open);
        setOpenNuevoEquipo(!openNuevoEquipo);
    };

    const handleClose = () => {
        setOpen(!open);
        setOpenNuevoEquipo(!openNuevoEquipo);
        setOpenEditEquipo(!openEditEquipo);
    };

    const handleCrear = async (newEquipo) => {
        try {
            await sendRequest('post', `${URL}/${pais}/equipos`, newEquipo);
            handleClose();
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePaginationChange = (event, page) => {
        setPage(page);
        /* setLimit(pageSize); */
    };

    const handleEditClick = () => {
        console.log('Edito');
    };

    const handleAsignar = (equipo) => {
        navigate('/app/equipos/clientes/asignar', {
            state: {
                equipo,
            },
        });
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={6} sm={6} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}>
                            Equipos
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleCreateClick}>
                            Crear nuevo equipo
                        </Button>
                    </Grid>
                </Grid>
                <ListHeader
                    lideres={lideres}
                    campanas={campanas}
                    departamentos={departamentos}
                    ciudades={ciudades}
                    filtros={filtros}
                    setFiltros={setFiltros}></ListHeader>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={equipos ? equipos : []}
                    columns={columns}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}></DataGrid>
                {/* {loading && (
                    <CircularProgress sx={{ mt: 10 }}></CircularProgress>
                )}
                {!loading &&
                    equipos &&
                    equipos.map((equipo) => {
                        return (
                            <Card
                                key={equipo.id}
                                sx={{
                                    marginTop: '10px',
                                    maxWidth: '95%',
                                    marginLeft: '25px',
                                }}>
                                <Typography
                                    variant="h5"
                                    fontWeight="bold"
                                    fontStyle="italic"
                                    sx={{
                                        textAlign: 'start',
                                        marginLeft: '10px',
                                        marginTop: '20px',
                                    }}>
                                    <ExpandMore
                                        expand={equipo.expanded}
                                        onClick={() =>
                                            handleExpandClick(equipo.id)
                                        }
                                        aria-expanded={equipo.expanded}
                                        aria-label="show more">
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                    {equipo.nombre} - {equipo.campana?.nombre}
                                    {equipo.campana?.division && (
                                        <Chip
                                            sx={{ ml: 2 }}
                                            label={
                                                equipo.campana?.division?.nombre
                                            }
                                            color={
                                                equipo.campana?.division?.id ===
                                                1
                                                    ? 'publicidad'
                                                    : 'fundraising'
                                            }
                                        />
                                    )}
                                    {equipo.campana?.sector && (
                                        <Chip
                                            sx={{ ml: 2 }}
                                            label={
                                                equipo.campana?.sector?.sector
                                            }
                                            color={
                                                equipo.campana?.sector?.id === 1
                                                    ? 'secondary'
                                                    : equipo.campana?.sector
                                                          ?.id === 2
                                                    ? 'automotriz'
                                                    : equipo.campana?.sector
                                                          ?.id === 3
                                                    ? 'entretenimiento'
                                                    : equipo.campana?.sector
                                                          ?.id === 4
                                                    ? 'hyt'
                                                    : equipo.campana?.sector
                                                          ?.id === 5
                                                    ? 'bienestar'
                                                    : 'secondary'
                                            }
                                        />
                                    )}
                                    {equipo.campana?.estrategia && (
                                        <Chip
                                            sx={{ ml: 2 }}
                                            label={equipo.campana?.estrategia}
                                            color={
                                                equipo.campana?.estrategia &&
                                                equipo.campana?.estrategia ===
                                                    'F2F'
                                                    ? 'f2f'
                                                    : 'tmk'
                                            }
                                        />
                                    )}
                                    {equipo.lider && (
                                        <Typography
                                            variant="p"
                                            fontWeight="normal"
                                            fontSize={16}
                                            sx={{ ml: 2 }}>
                                            Lider: {equipo.lider.nombres}{' '}
                                            {equipo.lider.apellidos}
                                        </Typography>
                                    )}
                                </Typography>
                            </Card>
                        );
                    })} */}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div>
                    {openNuevoEquipo && (
                        <NuevoEquipo
                            campanas={campanas}
                            lideres={lideres}
                            handleCrear={handleCrear}
                            handleClose={handleClose}
                            /* divisiones={divisiones}
                            canales={canales}
                            sectores={sectores}
                            pais={pais}
                            handleClose={handleClose}
                            handleAgregar={handleAgregar} */
                        ></NuevoEquipo>
                    )}
                    {openEditEquipo && (
                        <EditarEquipo
                            campanas={campanas}
                            lideres={lideres}
                            /* campanaRow={campanaRow}
                            divisiones={divisiones}
                            canales={canales}
                            sectores={sectores}
                            pais={pais}
                            handleClose={handleClose}
                            handleEditar={handleEditar} */
                        ></EditarEquipo>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default ConsultaEquipo;
