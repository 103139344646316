import { AUTHENTICATED, SIGNOUT_SUCCESS } from '../constants/auth';

const initialState = {
    token: null,
    refreshToken: null,
    accessToken: null,
    role: null,
    usuario: null,
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                token: action.token,
                refreshToken: action.refreshToken,
                accessToken: action.accessToken,
                role: action.role,
                usuario: action.usuario,
            };
        case SIGNOUT_SUCCESS:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default auth;
