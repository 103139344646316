import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useState } from 'react';

const EnviosInternos = ({ pais }) => {
    const [rows /* setRows */] = useState();
    const [limit, setLimit] = useState(50);
    const [, /* page */ setPage] = useState(0);
    const [rowCount /* setRowCount */] = useState(null);
    /* const [clientRow, setClientRow] = useState(null);
    const [open, setOpen] = useState(false);

    //Condicionales
    const [isEditing, setIsEditing] = useState(false);
    const [verHistorial, setVerHistorial] = useState(false); */
    const [loading /* setLoading */] = useState(false);

    const theme = useTheme();

    const columns = [
        {
            field: 'numero',
            headerName: 'Número',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'campana',
            headerName: 'Campaña',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'origen',
            headerName: 'Origen',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'destino',
            headerName: 'Destino',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'Cantidad',
            headerName: 'Cantidad',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'no_guia',
            headerName: 'No. de Guía',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'empresa_envio',
            headerName: 'Empresa Envío',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'estado',
            headerName: 'Estado',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Typography
                    variant="h4"
                    color="primary"
                    sx={{
                        textAlign: 'start',
                        marginLeft: '10px',
                        marginTop: '20px',
                    }}>
                    Envíos Internos
                </Typography>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default EnviosInternos;
