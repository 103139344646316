import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import Login from './auth-views/login/login';
import AppLayout from './app-layout';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PasswordChallenge from './auth-views/passwordChallenge';

const Views = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = useSelector((state) => state.auth.token);
    const usuario = useSelector((state) => state.auth.usuario);

    useEffect(() => {
        if (
            !token &&
            (location.pathname !== '/auth/passwordChallenge' ||
                location.pathname !== '/auth/forgotPassword')
        ) {
            navigate('/auth/login');
        }
        if (token && location.pathname === '/auth/login') {
            if (usuario?.rol?.id === 7) {
                navigate('/app/promotor');
            } else {
                navigate('/app/dashboard');
            }
        }
        if (!usuario) {
            navigate('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <Routes>
            <Route path="auth">
                <Route path="login" element={<Login></Login>}></Route>
                <Route
                    path="passwordChallenge"
                    element={<PasswordChallenge></PasswordChallenge>}></Route>
            </Route>
            <Route path="app/*" element={<AppLayout></AppLayout>}></Route>
            <Route
                path="/*"
                element={<Navigate to="/auth/login"></Navigate>}></Route>
        </Routes>
    );
};

export default Views;
