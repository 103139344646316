export const ROLES = Object.freeze({
    GERENCIA_EFFECTIVE: 'Gerencia',
    DIRECTOR_ADMINISTRATIVO: 'Director Administrativo',
    LOGISTICA: 'Logística',
    IMPRESION: 'Impresión',
    GERENCIA_WINNER: 'Gerencia Winner',
    DIRECTOR_OPERATIVO: 'Director Operativo',
    PROMOTOR: 'Promotor',
});

/* export const PANAMA_ROLES = Object.freeze({
    GERENCIA_EFFECTIVE: 'SUPER_ADMIN',
    DIRECTOR_ADMINISTRATIVO: 'ADMIN',
    LOGISTICA: '',
    IMPRESION: '',
    GERENCIA_WINNER: '',
    DIRECTOR_OPERATIVO: '',
    PROMOTOR: '',
}); */
