import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    borderBottom: 'solid #0B3E83 1px',
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 2,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

export default function DetalleCliente({
    handleClose,
    handleVerHistorial,
    clientRow,
    isEditing,
    setIsEditing,
    handleGuardar,
    tipoDocumentos,
    generos,
    estadosCiviles,
    edadesHijosList,
    nivelDeEstudiosList,
    departamentosList,
    departamento,
    setDepartamento,
    ciudadesList,
}) {
    const [id] = useState(clientRow.id);
    const [nombres /* setNombres */] = useState(clientRow.nombres);
    const [apellidos /* setApellidos */] = useState(clientRow.apellidos);
    const [fechaNacimiento, setFechaNacimiento] = useState(
        clientRow.fecha_nacimiento,
    );
    const [edad, setEdad] = useState(clientRow.edad);
    const [tipoDocumento, setTipoDocumento] = useState(
        clientRow.tipo_documento?.id,
    );
    const [numeroDocumento, setNumeroDocumento] = useState(
        clientRow.numero_documento,
    );
    const [genero, setGenero] = useState(clientRow.genero?.id);
    const [telFijo /* setTelFijo */] = useState(clientRow.tel_fijo);
    const [celular /* setCelular */] = useState(clientRow.celular);
    const [correo, setCorreo] = useState(clientRow.correo);
    const [tieneTc, setTieneTc] = useState(clientRow.tiene_tc);
    const [donanteRecurrente, setDonanteRecurrente] = useState(
        clientRow.donante_recurrente,
    );
    const [ciudad, setCiudad] = useState(clientRow.ciudad?.id);
    const [direccionExacta, setDireccionExacta] = useState(
        clientRow.direccion_exacta,
    );
    const [estadoCivil, setEstadoCivil] = useState(clientRow.estado_civil?.id);
    const [hijos, setHijos] = useState(clientRow.hijos);
    const [edadesHijos, setEdadesHijos] = useState(clientRow.edades_hijos?.id);
    const [nivelDeEstudios, setNivelDeEstudios] = useState(
        clientRow.nivel_de_estudios?.id,
    );
    const [ocupacion, setOcupacion] = useState(clientRow.ocupacion);
    const [empresa, setEmpresa] = useState(clientRow.empresa);
    const [direccionEmpresa, setDireccionEmpresa] = useState(
        clientRow.direccion_empresa,
    );
    const [tratamientoDatos, setTratamientoDatos] = useState(
        clientRow.tratamiento_datos,
    );
    const [estaEnCampana, setEstaEnCampana] = useState(
        clientRow.esta_en_campana,
    );
    const [updatedAt] = useState(clientRow.updated_at);

    const handleChangeTipoDocumento = (event) => {
        setTipoDocumento(event.target.value);
    };

    const handleChangeTieneTc = (event) => {
        setTieneTc(event.target.value);
    };

    const handleChangeDonanteRecurrente = (event) => {
        setDonanteRecurrente(event.target.value);
    };

    const handleHijos = (event) => {
        setHijos(event.target.value);
    };

    const handleTratamientoDatos = (event) => {
        setTratamientoDatos(event.target.value);
    };

    const handleEstaEnCampana = (event) => {
        setEstaEnCampana(event.target.value);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary">
                        {isEditing ? 'Editar' : 'Detalle'} cliente
                    </Typography>
                </Box>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Información Personal
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled
                            fullWidth
                            id="cliente_id"
                            label="ID"
                            value={id}
                            variant="outlined"
                            width="100%"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="nombres"
                            label="Nombres"
                            value={nombres}
                            variant="outlined"
                            width="100%"
                            disabled
                            /* onChange={(event) => setNombres(event.target.value)} */
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="apellidos"
                            label="Apellidos"
                            value={apellidos}
                            variant="outlined"
                            width="100%"
                            disabled
                            /* onChange={(event) =>
                                setApellidos(event.target.value)
                            } */
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                InputProps={{
                                    readOnly: !isEditing,
                                }}
                                sx={{ width: '100%' }}
                                label="Fecha de nacimiento"
                                value={dayjs(fechaNacimiento)}
                                onChange={(event) =>
                                    setFechaNacimiento(
                                        dayjs(event).toISOString(),
                                    )
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="edad"
                            label="Edad"
                            value={edad}
                            variant="outlined"
                            width="100%"
                            onChange={(event) => setEdad(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="tipo-documento">
                                Tipo Documento
                            </InputLabel>
                            <Select
                                readOnly={!isEditing}
                                labelId="tipo-documento"
                                label="Tipo Documento"
                                value={tipoDocumento}
                                onChange={handleChangeTipoDocumento}>
                                <MenuItem disabled value="">
                                    Seleccione un tipo de documento
                                </MenuItem>
                                {tipoDocumentos.map((tipoDocumento) => {
                                    return (
                                        <MenuItem
                                            key={tipoDocumento.id}
                                            value={tipoDocumento.id}>
                                            {tipoDocumento.tipo}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="numero_documento"
                            label="Número de Documento"
                            value={numeroDocumento}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setNumeroDocumento(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            select
                            fullWidth
                            id="genero"
                            label="Genero"
                            value={genero}
                            variant="outlined"
                            width="100%"
                            onChange={(event) => setGenero(event.target.value)}>
                            {generos &&
                                generos.map((genero) => {
                                    return (
                                        <MenuItem
                                            key={genero.id}
                                            value={genero.id}>
                                            {genero.genero}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="tel_fijo"
                            label="Teléfono Fijo"
                            value={telFijo}
                            variant="outlined"
                            width="100%"
                            disabled
                            /* onChange={(event) => setTelFijo(event.target.value)} */
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="celular"
                            label="Celular"
                            value={celular}
                            variant="outlined"
                            width="100%"
                            /* onChange={(event) => setCelular(event.target.value)} */
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="correo"
                            label="Correo"
                            value={correo}
                            variant="outlined"
                            width="100%"
                            onChange={(event) => setCorreo(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="tienetc">
                                Tiene Tarjeta de Crédito
                            </InputLabel>
                            <Select
                                readOnly={!isEditing}
                                labelId="tienetc"
                                label="Tiene Tarjeta de Crédito"
                                value={tieneTc}
                                onChange={handleChangeTieneTc}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="donante-recurrente">
                                Donante Recurrente
                            </InputLabel>
                            <Select
                                readOnly={!isEditing}
                                labelId="donante-recurrente"
                                label="Donante Recurrente"
                                value={donanteRecurrente}
                                onChange={handleChangeDonanteRecurrente}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Ubicación
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            select
                            fullWidth
                            id="departamento"
                            label="Departamento"
                            value={departamento}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setDepartamento(event.target.value)
                            }>
                            {departamentosList &&
                                departamentosList.map((departamento) => {
                                    return (
                                        <MenuItem
                                            key={departamento.id}
                                            value={departamento.id}>
                                            {departamento.nombre}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    {departamento && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                InputProps={{
                                    readOnly: !isEditing,
                                }}
                                select
                                fullWidth
                                id="ciudad"
                                label="Ciudad"
                                value={ciudad}
                                variant="outlined"
                                width="100%"
                                onChange={(event) =>
                                    setCiudad(event.target.value)
                                }>
                                {ciudadesList &&
                                    ciudadesList.map((ciudad) => {
                                        return (
                                            <MenuItem
                                                key={ciudad.id}
                                                value={ciudad.id}>
                                                {ciudad.nombre}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="direccion_exacta"
                            label="Dirección Exacta"
                            value={direccionExacta}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setDireccionExacta(event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Hogar
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            select
                            id="estado_civil"
                            label="Estado Civil"
                            value={estadoCivil}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setEstadoCivil(event.target.value)
                            }>
                            {estadosCiviles &&
                                estadosCiviles.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.estado}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="hijos">Hijos</InputLabel>
                            <Select
                                readOnly={!isEditing}
                                labelId="hijos"
                                label="hijos"
                                value={hijos}
                                onChange={handleHijos}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {hijos && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                InputProps={{
                                    readOnly: !isEditing,
                                }}
                                fullWidth
                                select
                                id="edades_hijos"
                                label="Edades Hijos"
                                value={edadesHijos}
                                variant="outlined"
                                width="100%"
                                onChange={(event) =>
                                    setEdadesHijos(event.target.value)
                                }>
                                {edadesHijosList &&
                                    edadesHijosList.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}>
                                                {item.rango}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </Grid>
                    )}
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Formación Académica y Profesional
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            select
                            id="nivel_de_estudios"
                            label="Nivel de Estudios"
                            value={nivelDeEstudios}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setNivelDeEstudios(event.target.value)
                            }>
                            {nivelDeEstudiosList &&
                                nivelDeEstudiosList.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.nivel}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="ocupacion"
                            label="Ocupación"
                            value={ocupacion}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setOcupacion(event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="empresa"
                            label="Empresa"
                            value={empresa}
                            variant="outlined"
                            width="100%"
                            onChange={(event) => setEmpresa(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            InputProps={{
                                readOnly: !isEditing,
                            }}
                            fullWidth
                            id="direccion_empresa"
                            label="Dirección Empresa"
                            value={direccionEmpresa}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setDireccionEmpresa(event.target.value)
                            }
                        />
                    </Grid>
                </Grid>
                <Typography id="modal-modal-description" sx={{ my: 2 }}>
                    Otros
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="tratamiento-datos">
                                Tratamiento de Datos
                            </InputLabel>
                            <Select
                                readOnly={!isEditing}
                                labelId="tratamiento-datos"
                                label="Tratamiento Datos"
                                value={tratamientoDatos}
                                onChange={handleTratamientoDatos}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="esta-en-campana">
                                Está en Campaña Activa
                            </InputLabel>
                            <Select
                                readOnly={!isEditing}
                                labelId="esta-en-campana"
                                label="esta-en-campana"
                                value={estaEnCampana}
                                onChange={handleEstaEnCampana}>
                                <MenuItem value={true}>Si</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disabled
                                InputProps={{
                                    readOnly: !isEditing,
                                }}
                                fullWidth
                                label="Modificado por Última vez"
                                value={dayjs(updatedAt)}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        {isEditing && (
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleClose}>
                                Cerrar
                            </Button>
                        )}
                        {!isEditing && (
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleVerHistorial}>
                                Ver Historial
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {isEditing && (
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() =>
                                    handleGuardar({
                                        id,
                                        nombres,
                                        apellidos,
                                        fecha_nacimiento: fechaNacimiento,
                                        edad,
                                        tipo_documento: tipoDocumento,
                                        numero_documento: numeroDocumento,
                                        genero,
                                        tel_fijo: telFijo,
                                        celular,
                                        correo,
                                        tiene_tc: tieneTc,
                                        donante_recurrente: donanteRecurrente,
                                        ciudad,
                                        departamento,
                                        direccion_exacta: direccionExacta,
                                        estado_civil: estadoCivil,
                                        hijos,
                                        edades_hijos: edadesHijos,
                                        nivel_de_estudios: nivelDeEstudios,
                                        ocupacion,
                                        empresa,
                                        direccion_empresa: direccionEmpresa,
                                        tratamiento_datos: tratamientoDatos,
                                        esta_en_campana: estaEnCampana,
                                        updatedAt,
                                    })
                                }>
                                Guardar
                            </Button>
                        )}
                        {!isEditing && (
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setIsEditing(true)}>
                                Editar
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
