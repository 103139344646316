import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { completeNewPasswordChallenge } from '../../../services/CognitoAuthService';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Collapse,
    CssBaseline,
    Grid,
    Paper,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material';
import Logos from '../../../images/logos.png';
import BGImage from '../../../images/Login BG image.png';

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}>
            {'Copyright © '}
            <Link
                color="inherit"
                href="https://cuponesybeneficios.com.co/quienes-somos/">
                Cupones y Beneficios
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const PasswordChallenge = () => {
    const [alert, setAlert] = useState({
        show: false,
        title: '',
        message: '',
        type: '',
    });
    const [validate, setValidation] = useState({
        show: false,
        helperText: '',
        newPassword: null,
        confirmPassword: null,
    });
    const navigate = useNavigate();
    const defaultTheme = useTheme();

    const onResetPassword = async (event) => {
        try {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            await completeNewPasswordChallenge(data.get('confirm_password'));
            setAlert({
                show: true,
                message: 'Contraseña cambiada correctamente',
            });
            setTimeout(() => {
                setAlert({
                    show: false,
                    title: '',
                    message: '',
                });
                navigate('/auth/login');
            }, 2000);
        } catch (error) {
            setAlert({
                show: true,
                message: handleOnResetPasswordError(error),
                type: 'error',
            });
            setTimeout(() => {
                setAlert({ show: false, title: '', message: '' });
            }, 5000);
        }
    };

    const validatePasswords = (newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            setValidation({
                show: true,
                helperText: 'Las contraseñas no coinciden',
                newPassword,
                confirmPassword,
            });
        } else {
            setValidation({
                ...validate,
                show: false,
                helperText: '',
                newPassword,
                confirmPassword,
            });
        }
    };

    const handleOnResetPasswordError = (error) => {
        if (error.name === 'InvalidPasswordException') {
            if (error.message.includes('^\\S.*\\S$')) {
                return 'La contraseña no tiene al menos 8 caracteres, verifica e intenta nuevamente';
            }
            if (error.message.includes('uppercase')) {
                return 'La contraseña no tiene al menos 1 mayúscula';
            }
            if (error.message.includes('numeric')) {
                return 'La contraseña no tiene al menos 1 carácter numérico';
            }
            if (error.message.includes('symbol')) {
                return 'La contraseña no tiene al menos 1 símbolo';
            }
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component="main"
                sx={{
                    height: '100vh',
                    backgroundImage: `url('${BGImage}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                <Grid
                    container
                    component={Paper}
                    elevation={5}
                    square
                    sx={{
                        height: '90vh',
                        width: '90vw',
                        marginY: '5vh',
                        marginX: '5vw',
                    }}>
                    <CssBaseline />
                    <Grid item xs={12} sm={8} md={6}>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <Typography component="h1" variant="h5">
                                Establecer Nueva Contraseña
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: '10vh',
                                    maxHeight: { xs: '10vh', md: '15vh' },
                                }}
                                alt="logos"
                                src={Logos}></Box>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={onResetPassword}
                                sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="newPassword"
                                    label="Nueva contraseña"
                                    name="newPassword"
                                    autoComplete="current-password"
                                    autoFocus
                                    type="password"
                                    onChange={(event) =>
                                        validatePasswords(
                                            event.target.value,
                                            validate.confirmPassword,
                                        )
                                    }
                                />
                                <TextField
                                    error={validate.show}
                                    helperText={validate.helperText}
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirm_password"
                                    label="Confirmar Contraseña"
                                    type="password"
                                    id="confirm_password"
                                    autoComplete="current-password"
                                    onChange={(event) =>
                                        validatePasswords(
                                            validate.newPassword,
                                            event.target.value,
                                        )
                                    }
                                />
                                {alert.show && (
                                    <Collapse in={alert.show}>
                                        <Alert severity={alert.type}>
                                            <AlertTitle
                                                sx={{ textAlign: 'left' }}>
                                                {alert.title}
                                            </AlertTitle>
                                            {alert.message}
                                        </Alert>
                                    </Collapse>
                                )}
                                <Button
                                    disabled={validate.show}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}>
                                    Actualizar Contraseña
                                </Button>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={6}
                        sx={{
                            backgroundImage:
                                'url(https://source.unsplash.com/random?wallpapers)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light'
                                    ? t.palette.grey[50]
                                    : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default PasswordChallenge;
