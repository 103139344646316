import { Box, Card, MenuItem, TextField, Typography } from '@mui/material';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '10px',
};

const ListHeader = ({ filtros, setFiltros, tipoDocumentos, generos }) => {
    const handleChangeSearchId = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchId: e.target.value });
            /* setSearchId(e.target.value); */
        }, 2000);
    };

    const handleChangeSearchNombres = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchNombres: e.target.value });
            /* setSearchNombres(e.target.value); */
        }, 2000);
    };

    const handleChangeSearchApellidos = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchApellidos: e.target.value });
            /* setSearchApellidos(e.target.value); */
        }, 2000);
    };

    const handleSelectTipoDocumento = (e) => {
        setFiltros({ ...filtros, tipoDocumentoSelected: e.target.value });
        /* setTipoDocumentoSelected(e.target.value); */
    };

    const handleChangeSearchNumeroDoc = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchNumeroDoc: e.target.value });
            /* setSearchNumeroDoc(e.target.value); */
        }, 2000);
    };

    const handleSelectGenero = (e) => {
        setFiltros({ ...filtros, generoSelected: e.target.value });
        /* setGeneroSelected(e.target.value); */
    };

    const handleChangeSearchCelular = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchCelular: e.target.value });
            /* setSearchCelular(e.target.value); */
        }, 2000);
    };

    const handleChangeSearchCorreo = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, searchCorreo: e.target.value });
            /* setSearchCorreo(e.target.value); */
        }, 2000);
    };

    const handleSelectTratamientoDatos = (e) => {
        setFiltros({ ...filtros, tratamientoDatosSelected: e.target.value });
        /* setTratamientoDatosSelected(e.target.value); */
    };

    return (
        <Box sx={style} component="form">
            <Card
                variant="outlined"
                sx={{ backgroundColor: '#D9D9D9', width: '85%', mt: 1 }}>
                <Typography color="primary" variant="h6" sx={{ pl: 2, pb: 2 }}>
                    Filtros
                </Typography>
                <TextField
                    placeholder="ID"
                    size="small"
                    variant="standard"
                    sx={{
                        width: '10%',
                        marginRight: '10px',
                        pl: 1,
                        mb: 2,
                        ml: 1,
                    }}
                    onChange={handleChangeSearchId}></TextField>
                <TextField
                    placeholder="Nombres"
                    size="small"
                    variant="standard"
                    sx={{
                        width: '19%',
                        marginRight: '10px',
                    }}
                    onChange={handleChangeSearchNombres}></TextField>
                <TextField
                    placeholder="Apellidos"
                    size="small"
                    variant="standard"
                    sx={{ width: '6.5%', marginRight: '10px' }}
                    onChange={handleChangeSearchApellidos}></TextField>
                <TextField
                    select
                    size="small"
                    value={filtros.tipoDocumentoSelected}
                    variant="standard"
                    sx={{ width: '8%', marginRight: '10px' }}
                    onChange={handleSelectTipoDocumento}>
                    <MenuItem value="tipo">Tipo Doc.</MenuItem>
                    {tipoDocumentos &&
                        tipoDocumentos.map((tipoDocumento) => {
                            return (
                                <MenuItem
                                    key={tipoDocumento.id}
                                    value={tipoDocumento.id}>
                                    {tipoDocumento.tipo}
                                </MenuItem>
                            );
                        })}
                </TextField>
                <TextField
                    placeholder="N° doc."
                    size="small"
                    variant="standard"
                    sx={{ width: '8%', marginRight: '10px' }}
                    onChange={handleChangeSearchNumeroDoc}></TextField>
                <TextField
                    select
                    size="small"
                    value={filtros.generoSelected}
                    variant="standard"
                    sx={{ width: '7%', marginRight: '10px' }}
                    onChange={handleSelectGenero}>
                    <MenuItem value="genero">Género</MenuItem>
                    {generos.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {item.genero}
                            </MenuItem>
                        );
                    })}
                </TextField>
                <TextField
                    placeholder="Celular"
                    size="small"
                    variant="standard"
                    sx={{ width: '6%', marginRight: '10px' }}
                    onChange={handleChangeSearchCelular}></TextField>
                <TextField
                    placeholder="Correo"
                    size="small"
                    variant="standard"
                    sx={{ width: '18%', marginRight: '10px' }}
                    onChange={handleChangeSearchCorreo}></TextField>
                <TextField
                    select
                    size="small"
                    value={filtros.tratamientoDatosSelected}
                    variant="standard"
                    sx={{ width: '9%', marginRight: '10px' }}
                    onChange={handleSelectTratamientoDatos}>
                    <MenuItem value="tratamiento">Trat. Datos</MenuItem>
                    <MenuItem value="1">Si</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                </TextField>
            </Card>
        </Box>
    );
};

export default ListHeader;
