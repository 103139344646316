import { Route, Routes } from 'react-router-dom';
import ConsultaCliente from './cliente/consulta';
import ConsultaCampana from './campaña/consulta';
import ConsultaEquipo from './equipo/consulta';
import TabsInventario from './inventario/tabsInventario';
import CampanaAsignarCliente from './campaña/clientes';
import EquipoAsignarCliente from './equipo/cliente';
import PromotorView from './promotor';
import ValidarOrdenImpresion from './inventario/ordenesInventario/validarOrdenImpresion';
import CampanaClientesVerDetalle from './campaña/clientes/verDetalle';
import Mantenimiento from './mantenimiento';

const AppViews = ({ pais }) => {
    return (
        <Routes>
            <Route
                path="clientes"
                element={
                    <ConsultaCliente pais={pais}></ConsultaCliente>
                }></Route>
            <Route
                path="campanas"
                element={
                    <ConsultaCampana pais={pais}></ConsultaCampana>
                }></Route>
            <Route
                path="campana/clientes"
                element={
                    <CampanaClientesVerDetalle
                        pais={pais}></CampanaClientesVerDetalle>
                }></Route>
            <Route
                path="campana/clientes/asignar"
                element={
                    <CampanaAsignarCliente pais={pais}></CampanaAsignarCliente>
                }></Route>
            <Route
                path="equipos"
                element={<ConsultaEquipo pais={pais}></ConsultaEquipo>}></Route>
            <Route
                path="equipos/clientes/asignar"
                element={
                    <EquipoAsignarCliente pais={pais}></EquipoAsignarCliente>
                }></Route>
            <Route
                path="inventario"
                element={<TabsInventario pais={pais}></TabsInventario>}></Route>
            <Route
                path="inventario/orden-impresion/validar"
                element={
                    <ValidarOrdenImpresion pais={pais}></ValidarOrdenImpresion>
                }></Route>
            <Route
                path="promotor/*"
                element={<PromotorView pais={pais}></PromotorView>}></Route>
            <Route
                path="mantenimiento"
                element={<Mantenimiento></Mantenimiento>}></Route>
        </Routes>
    );
};

export default AppViews;
