import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Box, Typography, Grid, Button, Modal } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import ModalAsignarClientePromotor from './modalAsignar';

dayjs.extend(weekOfYear);

const EquipoAsignarCliente = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);
    const [promotores, setPromotores] = useState([]);

    const URL = `${process.env.REACT_APP_API_URL}`;
    const theme = useTheme();
    const location = useLocation();

    // Condicionales
    const [loading, setLoading] = useState(false);

    // Campos selected
    const [rowsSelected, setRowsSelected] = useState([]);

    const equipo = location.state?.equipo;

    // Modal
    const [open, setOpen] = useState(false);

    //Re-render
    const [guardado, setGuardado] = useState(false);

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/cliente/equipo/${equipo.id}?limit=${limit}&page=${page}`,
        ).then((res) => {
            setLoading(false);
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [limit, page, guardado, URL, pais, equipo.id]);

    useEffect(() => {
        sendRequest('get', `${URL}/usuario/promotor`).then((res) =>
            setPromotores(res.data),
        );
    }, [URL]);

    const columns = [
        {
            field: 'campana',
            headerName: 'Campaña',
            flex: 0.6,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => {
                const nombresCampana = new Set(
                    row.tarjetones?.map(
                        (tarjeton) =>
                            `${tarjeton.campana.nombre} - ${dayjs(
                                tarjeton.campana.vigencia,
                            ).format('YYYY')}`,
                    ),
                );
                return [...nombresCampana].toString();
            },
        },
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 0.5,
            headerClassName: 'header',
            sortable: false,
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            sortable: false,
        },
        {
            field: 'tipo_documento',
            headerName: 'Tipo Doc.',
            headerClassName: 'header',
            sortable: false,
            align: 'center',
            valueGetter: ({ row }) => {
                return row.tipo_documento?.tipo;
            },
        },
        {
            field: 'numero_documento',
            headerName: 'Núm. Doc.',
            headerClassName: 'header',
            sortable: false,
        },
        {
            field: 'genero',
            headerName: 'Género',
            headerClassName: 'header',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.genero?.genero;
            },
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            sortable: false,
        },
        {
            field: 'correo',
            headerName: 'Correo',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
        },
        {
            field: 'tratamiento_datos',
            headerName: 'Trat. Datos',
            headerClassName: 'header',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                if (params.value) {
                    return <CheckIcon></CheckIcon>;
                } else {
                    return <ClearIcon></ClearIcon>;
                }
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleOpenAsignarModal = () => {
        if (rowsSelected.length > 0) {
            setOpen(!open);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAsignar = async (promotor) => {
        await sendRequest(
            'patch',
            `${URL}/${pais}/clientes-asignados/promotor`,
            {
                clientes: rowsSelected,
                campana: equipo.campana.id,
                equipo: equipo.id,
                lider: equipo.lider.id,
                promotor,
                cliente_promotor_current_week: dayjs(new Date()).week(),
            },
        );
        setGuardado(!guardado);
        handleClose();
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}>
                            Asignar Clientes / {equipo?.nombre}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleOpenAsignarModal}>
                            Asignar Clientes a Promotor
                        </Button>
                    </Grid>
                </Grid>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    checkboxSelection
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    onRowSelectionModelChange={(newRowSelection) => {
                        setRowsSelected(newRowSelection);
                    }}
                    sx={{
                        height: '80vh',
                        margin: '20px 10px',
                        '& .header': {
                            backgroundColor: theme.palette.primary.main,
                            color: '#ffffff',
                        },
                        '.MuiDataGrid-columnHeaderTitleContainer': {
                            backgroundColor: '#014181',
                            color: 'white',
                            '.MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <ModalAsignarClientePromotor
                    promotores={promotores}
                    handleClose={handleClose}
                    handleAsignar={handleAsignar}></ModalAsignarClientePromotor>
            </Modal>
        </>
    );
};

export default EquipoAsignarCliente;
