import { useTheme } from '@emotion/react';
import {
    Alert,
    Box,
    Button,
    Grid,
    Tooltip,
    Typography,
    styled,
    tooltipClasses,
} from '@mui/material';
import {
    DataGrid,
    GridEditInputCell,
    GridToolbar,
    esES,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../../services/request';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

const ValidarOrdenImpresion = ({ pais }) => {
    const [rows, setRows] = useState([{ id: 1, numero: '' }]);
    const [cantidadMaxima, setCantidadMaxima] = useState(0);

    // Alerta
    const [alert, setAlert] = useState({
        show: false,
        message: '',
    });

    //Condicionales
    const [loading /* setLoading */] = useState(false);

    /* //Re-render
    const [guardado, setGuardado] = useState(false); */

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        sendRequest(
            'get',
            `${URL}/${pais}/ordenes-impresion/${location.state?.orden.id}`,
        ).then((res) => {
            setCantidadMaxima(res.data.pendientes_por_validar);
        });
    }, [URL, pais, location.state?.orden.id]);

    const columns = [
        {
            field: 'items',
            headerName: 'Items',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.id,
        },
        {
            field: 'numero',
            headerName: 'Número Tarjeton',
            flex: 1,
            headerClassName: 'header',
            editable: true,
            sortable: false,
            preProcessEditCellProps: (params) => {
                let hasError = false;
                let errorMessage = '';
                const duplicated = rows.find(
                    (item) => item.numero === params.props.value,
                );
                if (duplicated?.numero) {
                    hasError = true;
                    errorMessage = `El número de tarjeton ${duplicated.numero} esta duplicado.`;
                }
                return {
                    ...params.props,
                    error: hasError,
                    errorMessage: errorMessage,
                };
            },
            renderEditCell: (props) => {
                const { error, errorMessage } = props;
                return (
                    <StyledTooltip open={!!error} title={errorMessage}>
                        <GridEditInputCell {...props}></GridEditInputCell>
                    </StyledTooltip>
                );
            },
        },
    ];

    const handleValidar = async () => {
        try {
            const newData = rows.filter((item) => item.numero);

            await sendRequest(
                'post',
                `${URL}/${pais}/ordenes-impresion/validar`,
                {
                    validos: newData,
                    ordenImpresion: {
                        id: location.state?.orden.id,
                        pendientes_por_validar: cantidadMaxima - newData.length,
                        campana: location.state?.orden?.campana?.id,
                    },
                },
            );
            navigate('/app/inventario', {
                state: {
                    tab: 1,
                },
            });
        } catch (error) {
            setRows(rows);
            setAlert({ show: true, message: error.response.data.error });
            setTimeout(() => setAlert({ show: false, message: '' }), 5000);
        }
    };

    const handleRowUpdate = async (updatedRow, originalRow) => {
        let newId = updatedRow?.id + 1;

        if (updatedRow?.numero && rows.length < cantidadMaxima) {
            setRows((prev) => [
                ...prev.map((item) => {
                    if (item?.id === updatedRow?.id) {
                        item.numero = updatedRow.numero;
                    }
                    return item;
                }),
                { id: newId, numero: '' },
            ]);
        } else {
            setRows((prev) =>
                prev.map((item) => {
                    if (item?.id === updatedRow.id) {
                        item.numero = updatedRow.numero;
                    }
                    return item;
                }),
            );
        }
    };

    const handleRowUpdateError = (error) => {
        console.log(error);
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Grid container spacing={5} py={3} pr={3}>
                <Grid item xs={12} md={8}>
                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                            textAlign: 'start',
                            marginLeft: '10px',
                        }}>
                        Validar Orden de Impresion
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleValidar}>
                        Validar
                    </Button>
                </Grid>
            </Grid>
            <Typography
                variant="p"
                sx={{
                    textAlign: 'start',
                    marginLeft: '10px',
                    marginTop: '20px',
                }}>
                <b>Orden N°:</b> {location.state?.orden?.id}
            </Typography>
            <Typography
                variant="p"
                sx={{
                    textAlign: 'start',
                    marginLeft: '30px',
                    marginTop: '20px',
                }}>
                <b>Campaña:</b> {location.state?.orden?.campana?.nombre}
            </Typography>
            <Typography
                variant="p"
                sx={{
                    textAlign: 'start',
                    marginLeft: '30px',
                    marginTop: '20px',
                }}>
                <b>Numeración:</b> {location.state?.orden?.numeracion}
            </Typography>
            <Typography
                variant="p"
                sx={{
                    textAlign: 'start',
                    marginLeft: '30px',
                    marginTop: '20px',
                }}>
                <b>Cantidad por validar:</b> {location.state?.orden?.cantidad}
            </Typography>
            <Typography
                variant="p"
                sx={{
                    textAlign: 'start',
                    marginLeft: '30px',
                    marginTop: '20px',
                }}>
                <b>Pendientes por validar:</b>{' '}
                {location.state?.orden?.pendientes_por_validar}
            </Typography>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item sm={7} lg={7}>
                    {alert.show && (
                        <Alert
                            variant="outlined"
                            severity="error"
                            sx={{ mt: 2 }}>
                            {alert.message}
                        </Alert>
                    )}
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        hideFooterPagination
                        hideFooter
                        loading={loading}
                        rows={rows ? rows : []}
                        columns={columns}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                                // Estos dos quitan el export
                                printOptions: {
                                    disableToolbarButton: true,
                                },
                                csvOptions: { disableToolbarButton: true },
                            },
                        }}
                        sx={{
                            height: '65vh',
                            margin: '20px 10px',
                            '& .header': {
                                backgroundColor: theme.palette.primary.main,
                                color: '#ffffff',
                            },
                            '.MuiDataGrid-columnHeaderTitleContainer': {
                                backgroundColor: '#014181',
                                color: 'white',
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                },
                            },
                            '& .Mui-error': {
                                height: '100%',
                                backgroundColor: `rgb(126,10,15, ${
                                    theme.palette.mode === 'dark' ? 0 : 0.1
                                })`,
                                color:
                                    theme.palette.mode === 'dark'
                                        ? '#ff4343'
                                        : '#750f0f',
                            },
                        }}
                        processRowUpdate={handleRowUpdate}
                        onProcessRowUpdateError={handleRowUpdateError}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ValidarOrdenImpresion;
