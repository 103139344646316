import { AUTHENTICATED, SIGNOUT_SUCCESS } from '../constants/auth';

export const authenticated = (
    token,
    refreshToken,
    accessToken,
    role,
    usuario,
) => {
    return {
        type: AUTHENTICATED,
        token,
        refreshToken,
        accessToken,
        role,
        usuario,
    };
};

export const signOutSuccess = () => {
    return {
        type: SIGNOUT_SUCCESS,
    };
};
