import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { Box, Grid } from '@mui/material';

//CSS
import './styles.css';

const Agenda = () => {
    return (
        <Box sx={{ width: '100%', pt: 2 }}>
            <Grid container spacing={3}>
                <Grid item sm={1}></Grid>
                <Grid item sm={10}>
                    <FullCalendar
                        selectable
                        selectMirror
                        editable
                        weekNumbers
                        plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                        ]}
                        initialView="timeGridWeek" //"dayGridMonth"
                        locale={esLocale}
                        aspectRatio={2.5}
                        slotLabelFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            omitZeroMinute: false,
                            hour12: false,
                        }}
                        dateClick={(value) =>
                            console.log(value)
                        }></FullCalendar>
                </Grid>
                <Grid item sm={1}></Grid>
            </Grid>
        </Box>
    );
};

export default Agenda;
