import {
    AuthenticationDetails,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';

export async function signIn(username, password) {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
            newPasswordRequired: async (userAttributes) => {
                delete userAttributes.email_verified;
                const data = { cognitoUser, userAttributes };
                sessionStorage.setItem(
                    'userDataPasswordChallenge',
                    JSON.stringify(data),
                );
                reject('changePassword');
            },
        });
    });
}

export async function forgotPassword(email) {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}

export async function completeNewPasswordChallenge(newPassword) {
    const userDataPasswordChallenge = JSON.parse(
        sessionStorage.getItem('userDataPasswordChallenge'),
    );

    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({
        Username: userDataPasswordChallenge.userAttributes.email,
        Pool: userPool,
    });

    cognitoUser.Session = userDataPasswordChallenge.cognitoUser.Session;

    delete userDataPasswordChallenge.userAttributes.email;

    return new Promise((resolve, reject) =>
        cognitoUser.completeNewPasswordChallenge(
            newPassword,
            userDataPasswordChallenge.userAttributes,
            {
                onSuccess: (result) => {
                    resolve(result);
                },
                onFailure: (err) => {
                    reject(err);
                },
            },
        ),
    );
}

export async function resetPassword(verificationCode, newPassword, email) {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
    });

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: (result) => {
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
}

export async function logOut() {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
        cognitoUser.signOut();
    }
}

export async function refreshCognitoToken(token, onSuccess, onError) {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });
    const refreshToken = new CognitoRefreshToken({
        RefreshToken: token,
    });
    const cognitoUser = new CognitoUser({
        Username: refreshToken,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, async (err, result) => {
            if (err) {
                reject(onError());
            } else {
                const response = result;
                resolve(await onSuccess(response));
            }
        });
    });
}

export function isTokenExpired(token) {
    const idToken = new CognitoIdToken({
        IdToken: token,
    });
    const payload = idToken.decodePayload();
    const currentTime = new Date().getTime() / 1000;
    if (payload.exp < currentTime) {
        return true;
    }
    return false;
}
