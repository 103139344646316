import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 24,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const ModalAsignarClientePromotor = ({
    promotores,
    handleClose,
    handleAsignar,
}) => {
    const [promotorSelected, setPromotorSelected] = useState(null);

    const handleSelectPromotor = (event) => {
        setPromotorSelected(event.target.value);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary">
                        Asignar Clientes a Promotor
                    </Typography>
                </Box>
                <Grid container spacing={5} sx={{ mt: '2px' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="promotor"
                            label="Promotores"
                            value={promotorSelected}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectPromotor}>
                            {promotores &&
                                promotores.map((promotor) => {
                                    return (
                                        <MenuItem
                                            key={promotor.id}
                                            value={promotor.id}>
                                            {promotor.nombres}{' '}
                                            {promotor.apellidos}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleAsignar(promotorSelected)}>
                            Asignar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ModalAsignarClientePromotor;
