import { Route, Routes } from 'react-router-dom';
import MiBD from './miBD';
import Agenda from './agenda';
import Preview from './miBD/preview';

const PromotorView = ({ pais }) => {
    return (
        <Routes>
            <Route
                path="preview"
                element={<Preview pais={pais}></Preview>}></Route>
            <Route path="bd" element={<MiBD pais={pais}></MiBD>}></Route>
            <Route path="agenda" element={<Agenda></Agenda>}></Route>
        </Routes>
    );
};

export default PromotorView;
