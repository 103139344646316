import { useState } from 'react';
import HeaderNav from '../../components/layout-components/headerNav';
import AppViews from '../app-views';

const AppLayout = () => {
    const [pais, setPais] = useState('colombia');
    return (
        <>
            <HeaderNav pais={pais} setPais={setPais}></HeaderNav>
            <AppViews pais={pais}></AppViews>
        </>
    );
};

export default AppLayout;
