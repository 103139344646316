import { Box, Grid, TablePagination } from '@mui/material';
import { thousandFormatter } from '../../../../utils/utils';

const CustomFooter = (props) => {
    return (
        <Box sx={{ padding: '10px', display: 'flex' }}>
            <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center">
                <Grid item>
                    Suma Total Donantes/Clientes:{' '}
                    {thousandFormatter.format(props.total)}
                </Grid>
                <Grid item>
                    <TablePagination
                        count={props.count}
                        page={props.page}
                        onPageChange={props.handlePaginationChange}
                        rowsPerPage={props.limit}
                        rowsPerPageOptions={[]}
                        onRowsPerPageChange={props.handleRowsPerPageChange}
                        color="primary"></TablePagination>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomFooter;
